import { FormikProps } from 'formik';
import React from 'react';
const styles = {
  labelFont: {
    fontSize: 16,
    fontWeight: 500,
    color: '#53575b',
  },
};
interface SummaryProps {
  formikProps: FormikProps<any>;
  handleTotalOtherCharge: () => string;
  handleTotalExtraCharge: () => string;
  handleTotalOtherDiscountAmt: () => string;
}
const Summary = React.forwardRef<HTMLDivElement, SummaryProps>((props, ref) => {
  // const location = useLocation();
  // const { gstAmt } = location.state;
  const {
    formikProps,
    handleTotalOtherCharge,
    handleTotalExtraCharge,
    // handleTotalOtherDiscountAmt,
  } = props;
  const { values } = formikProps;

  return (
    <div ref={ref} className="units-booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <h5>Summary</h5>

        <div className="row">
          <div className="col-12">
            <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
              <div className="form-row ">
                <div className="col-4">
                  <label style={styles.labelFont}>Basic amount</label>
                </div>
                <div className="col-3">
                  <label>:</label>
                </div>
                <div className="col-5">
                  <label>
                    <span> ₹ </span>
                    <span>{Number(values.basic_rate_basic_amount).toFixed(2)}</span>
                    <span> (+)</span>
                  </label>
                </div>
              </div>
              <div className="form-row ">
                <div className="col-4">
                  <label style={styles.labelFont}>Other charges total</label>
                </div>
                <div className="col-3">
                  <label>:</label>
                </div>
                <div className="col-5">
                  <label>
                    <span> ₹ </span>
                    <span> {values.calculation_method ? handleTotalOtherCharge() : '0.00'} </span>
                    <span> (+)</span>
                  </label>
                </div>
              </div>
              {/* <div className="form-row ">
                <div className="col-4">
                  <label style={styles.labelFont}>Total discount</label>
                </div>
                <div className="col-3">
                  <label>:</label>
                </div>
                <div className="col-5">
                  <label>
                    <span> ₹ </span>
                    <span>
                      {' '}
                      {isNaN(
                        parseFloat(handleTotalOtherDiscountAmt()) +
                          Number(values.basic_rate_disc_amt),
                      )
                        ? '0.00'
                        : (
                            parseFloat(handleTotalOtherDiscountAmt()) +
                            Number(values.basic_rate_disc_amt)
                          ).toFixed(2)}{' '}
                    </span>
                    <span> (+)</span>
                  </label>
                </div>
              </div> */}
              <div className="form-row ">
                <div className="col-4">
                  <label style={styles.labelFont}>Goverment taxes total</label>
                </div>
                <div className="col-3">
                  <label>:</label>
                </div>
                <div className="col-5">
                  <label>
                    <span> ₹ </span>
                    <span>
                      {isNaN(
                        Number(values.gst_amt) +
                          Number(values.stampduty_amount) +
                          Number(values.reg_amount),
                      )
                        ? `0.00`
                        : (
                            Number(values.gst_amt) +
                            Number(values.stampduty_amount) +
                            Number(values.reg_amount)
                          ).toFixed(2)}
                    </span>
                    <span> (+)</span>
                  </label>
                </div>
              </div>
              <div className="form-row ">
                <div className="col-4">
                  <label style={styles.labelFont}>Extra charges</label>
                </div>
                <div className="col-3">
                  <label>:</label>
                </div>
                <div className="col-5">
                  <label>
                    <span> ₹ </span>
                    <span> {values.calculation_method ? handleTotalExtraCharge() : '0.00'} </span>
                    <span> (+)</span>
                  </label>
                </div>
              </div>
              <div className="form-row total-background">
                <div className="col-4">
                  <label style={styles.labelFont}>Property Final Amount</label>
                </div>
                <div className="col-3 ">
                  <label>:</label>
                </div>
                <div className="col-5">
                  <label style={{ fontSize: '1.1rem' }}>
                    <span> ₹ </span>
                    <span>
                      {' '}
                      {values.calculation_method
                        ? isNaN(
                            Number(values.basic_rate_basic_amount) +
                              parseFloat(handleTotalOtherCharge()) +
                              Number(values.gst_amt) +
                              Number(values.stampduty_amount) +
                              Number(values.reg_amount) +
                              parseFloat(handleTotalExtraCharge()),
                          )
                          ? (
                              Number(values.basic_rate_basic_amount) +
                              parseFloat(handleTotalOtherCharge()) +
                              Number(values.gst_amt) +
                              Number(values.stampduty_amount) +
                              Number(values.reg_amount) +
                              parseFloat(handleTotalExtraCharge())
                            ).toFixed(2)
                          : (
                              Number(values.basic_rate_basic_amount) +
                              parseFloat(handleTotalOtherCharge()) +
                              Number(values.gst_amt) +
                              Number(values.stampduty_amount) +
                              Number(values.reg_amount) +
                              parseFloat(handleTotalExtraCharge())
                            ).toFixed(2)
                        : '0.00'}{' '}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Summary;
