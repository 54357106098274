/* eslint-disable @typescript-eslint/no-empty-function */
import './UE_BookingForm.css';

import { Box } from '@mui/material';
import logo from 'assets/images/nav-logo.png';
import Loader from 'components/atoms/Loader';
import { useFormik } from 'formik';
import html2canvas from 'html2canvas';
import Cookies from 'js-cookie';
import jsPDF from 'jspdf';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getAreaInfo,
  getOtherChargesList,
  getOtherExtraCharges,
  getUnitInfo,
  getUnitParkingInfo,
} from 'redux/sales';
import { useAppDispatch, useAppSelector } from 'redux/store';

import BookingHeader from './components/BookingHeader';
import CalculationMethod from './components/CalculationMethod';
import ExtraCharges from './components/ExtraCharges';
import GovtTaxes from './components/GovtTaxes';
import InstallmentSelection from './components/InstallmentSelection';
import OtherCharges from './components/OtherCharges';
import Summary from './components/Summary';
import UnitInfo from './components/UnitInfo';

//Sub Components

const UE_BookingForm_2 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { rate, type, gstAmt, gstPer } = location.state;

  // const edit = false;

  // url params
  // const project_id = searchParams.get('project_id');
  const project_id = Cookies.get('_hjproject_idUser_2492391');
  // const project_id = '260';
  // const unit_id = searchParams.get('unit_id');
  // const tower_id = searchParams.get('tower_id');
  // const autoIncremented_Tower = searchParams.get('tower_id');

  const unit_id = Cookies.get('_hjunit_idUser_2492391');
  const tower_id = Cookies.get('_hjtower_idUser_2492391');
  // const autoIncremented_Tower = Cookies.get('_hjtower_idUser_2492391');
  // const unit_id = '145';
  // const tower_id = '2';
  // redux state values
  const { unitParkingInfo, unitAreaInfo, otherChargesList, extraChargesList, loading, unitInfo } =
    useAppSelector(s => s.sales);

  const [extraCharges, setExtraCharges] = useState([]);
  const [baseAmount, setBaseAmount] = useState<number>();
  const [OCList, setOCList] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [heights, setHeights] = useState({});
  // const bookingHeaderRef = useRef(null);
  const unitInfoRef = useRef(null);
  const calculationMethodRef = useRef(null);
  const otherChargesRef = useRef(null);
  const extraChargesRef = useRef(null);
  const goverTaxesRef = useRef(null);
  const summaryRef = useRef(null);
  const installmentsRef = useRef(null);
  const measureHeights = () => {
    // setHeights({
    //   bookingHeaderHeight: bookingHeaderRef.current ? bookingHeaderRef.current.offsetHeight : 0,
    //   unitInfoHeight: unitInfoRef.current ? unitInfoRef.current.offsetHeight : 0,
    //   calculationMethodHeight: calculationMethodRef.current
    //     ? calculationMethodRef.current.offsetHeight
    //     : 0,
    //   otherChargesHeight: otherChargesRef.current ? otherChargesRef.current.offsetHeight : 0,
    //   extraChargesHeight: extraChargesRef.current ? extraChargesRef.current.offsetHeight : 0,
    //   goverTaxesHeight: goverTaxesRef.current ? goverTaxesRef.current.offsetHeight : 0,
    //   summaryHeight: summaryRef.current ? summaryRef.current.offsetHeight : 0,
    //   installmentsHeight: installmentsRef.current ? installmentsRef.current.offsetHeight : 0,
    //   // Add other components' heights here
    // });
  };

  useLayoutEffect(() => {
    measureHeights(); // Measure the heights after the component mounts
    window.addEventListener('resize', measureHeights); // Re-measure on window resize

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', measureHeights);
    };
  }, []); // Empty dependency array ensures it runs only once on mount

  // parkingInfo
  const unitParkingInfoValues = useMemo(() => {
    return unitParkingInfo?.all_parking_units?.filter(e => e.allotment_data === unit_id);
  }, [unitParkingInfo?.all_parking_units, unit_id]);

  // form initials
  const initialValues = useMemo(() => {
    return {
      project_id,
      unit_id: unit_id,
      parking_no: unitParkingInfoValues?.map(e => e.id).toString(),
      calculation_method: '',
      basic_rate_no: 1,
      basic_rate_description: 'Basic rate of unit',
      basic_rate_area: unitAreaInfo?.super_build_up_area || 0,
      basic_rate: type === 'clp_base' ? rate : unitAreaInfo?.rate_base_amt || 0,
      basic_rate_disc_amt: 0,
      basic_rate_disc_per: 0,
      basic_rate_basic_amount: 0,
      other_charges: [],
      other_charges_total: 0,
      other_charges_total_discount: 0,
      custom_payment_remark_id: 0,
      custom_payment_remark: '',
      disc_remarks: '',
      extra_charges: [],
      gst_per: gstPer,
      gst_amt: gstAmt,
      stampduty_per: 0,
      stampduty_amount: 0,
      reg_per: 0,
      reg_amount: 0,
      total_gove_tax: 0,
      is_loan: 'yes',
      loan_amt: 0,
      bank: 0,
      bank_id: 0,
      loan_remarks: '',
      custom_payment_total_amount: 0,
      extra_charges_total: 0,
      ownership: [],
      installment_type: '',
      installments: [],
      clp_installments: [],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    project_id,
    unitAreaInfo?.rate_base_amt,
    unitAreaInfo?.super_build_up_area,
    unitParkingInfoValues,
    unit_id,
  ]);

  // Api calls
  useEffect(() => {
    dispatch(getUnitInfo({ project_id: project_id, tower_id: tower_id }));
    dispatch(getUnitParkingInfo({ project_id: project_id }));
    dispatch(getAreaInfo({ project_id, project_main_types: 6, unit_id }));
    dispatch(getOtherChargesList({ project_id, unit_id }));
    dispatch(getOtherExtraCharges({ project_id, unit_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Charges list populate
  useEffect(() => {
    // OC
    handleMapOtherCharges();
    handle_Other_Charge_Row_Total();
    // EC
    handleUpdateExtraCharges();
    handle_Extra_Charge_Row_Total();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherChargesList, unitAreaInfo, extraChargesList]);

  // other charges update, delete
  function handleMapOtherCharges() {
    const OC = otherChargesList.other_charge_unit_rates;
    const updatedData = OC?.map(x => {
      return {
        amount_type: x?.amount_type,
        fixed_amounts: Number(x.fixed_amounts) || 0,
        ratebase_amounts: Number(x.ratebase_amounts) || 0,
        other_charges_no: x.id,
        other_charges_title: x.title,
        other_charges_distribution_method: 'Proportionately with all installment',
        other_charges_area: x.area || 0,
        other_charges_rate:
          x.amount_type === 'ratebase_amt' ? Number(x.ratebase_amounts) : Number(x.fixed_amounts),
        other_charges_disc_amt: 0,
        other_charges_disc_per: 0,
        other_charges_amount: 0,
        other_charges_base: 0,
      };
    });
    setOCList(updatedData);
  }

  const handleTotalOtherCharge = useCallback(() => {
    let total = 0;
    OCList?.forEach(charge => {
      total += parseFloat(charge?.other_charges_amount) || 0;
    });
    return Number(total).toFixed(2);
  }, [OCList]);

  const handleTotalOtherDiscountAmt = useCallback(() => {
    let total = 0;
    OCList?.forEach(charge => {
      total += parseFloat(charge?.other_charges_disc_amt) || 0;
    });
    return Number(total).toFixed(2);
  }, [OCList]);

  function handle_Other_Charge_Row_Total() {
    OCList?.map((x, index) => {
      const calculatedAmount =
        x.amount_type === 'ratebase_amt'
          ? Number(x.other_charges_area) * x.other_charges_rate
          : x.fixed_amounts;

      setOCList(prevList => {
        const newUnitRates = [...prevList];
        newUnitRates[index] = {
          ...newUnitRates[index],
          other_charges_amount:
            calculatedAmount === 0
              ? 0
              : calculatedAmount.toFixed(2) - newUnitRates[index].other_charges_disc_amt,
        };
        return newUnitRates;
      });
    });
  }

  const handleOCListChange = (index, field, value) => {
    setOCList(prevList => {
      const newUnitRates = [...prevList];
      newUnitRates[index] = {
        ...newUnitRates[index],
        [field]: value,
      };

      const OC_ROW_BASE_AMT =
        newUnitRates[index].amount_type === 'ratebase_amt'
          ? newUnitRates[index].other_charges_area * newUnitRates[index].other_charges_rate
          : newUnitRates[index].fixed_amounts;

      const discountAmt = newUnitRates[index].other_charges_disc_amt;

      const totalAmount = OC_ROW_BASE_AMT === 0 ? 0 : Number(OC_ROW_BASE_AMT) - Number(discountAmt);

      newUnitRates[index].other_charges_amount = totalAmount.toFixed(2);

      return [...newUnitRates];
    });
  };

  // extra charges update, delete & add
  function handleUpdateExtraCharges() {
    const EC = extraChargesList?.other_charge_unit_rates;

    const updatedData = EC?.map(x => ({
      amount_type: x?.amount_type,
      fixed_amounts: Number(x.fixed_amounts) || 0,
      ratebase_amounts: Number(x.ratebase_amounts) || 0,
      title: x.title,
      extra_charges_no: x.id,
      extra_charges_title: x.title,
      extra_charges_distribution_method: 'Proportionately with all installment',
      extra_charges_area: x.area || 0,
      extra_charges_rate:
        x.amount_type === 'ratebase_amt' ? Number(x.ratebase_amounts) : Number(x.fixed_amounts),
      extra_charges_disc_amt: 0,
      extra_charges_disc_per: 0,
      extra_charges_amt: 0,
      extra_charges_total: 0,
      extra_charges_base: 0,
    }));

    setExtraCharges(updatedData);

    handle_Extra_Charge_Row_Total();
  }

  const handleUpdateExtraCharge = (index: number, field: string, value) => {
    setExtraCharges(prevExtraCharges => {
      const updatedExtraCharges = [...prevExtraCharges];
      updatedExtraCharges[index][field] = value;

      const EXTRA_CHARGE_BASE =
        updatedExtraCharges[index].amount_type === 'ratebase_amt'
          ? updatedExtraCharges[index].extra_charges_area *
            updatedExtraCharges[index].ratebase_amounts
          : updatedExtraCharges[index].fixed_amounts;

      const discountAmt = updatedExtraCharges[index].extra_charges_disc_amt;

      const totalAmount = EXTRA_CHARGE_BASE === 0 ? 0 : EXTRA_CHARGE_BASE - discountAmt;

      updatedExtraCharges[index].extra_charges_total = totalAmount;

      return updatedExtraCharges;
    });
  };

  function handle_Extra_Charge_Row_Total() {
    setExtraCharges(prevList =>
      prevList?.map(x => {
        const Amt =
          x.amount_type === 'ratebase_amt'
            ? Number(x.extra_charges_area) * x.extra_charges_rate
            : x.extra_charges_rate;
        return {
          ...x,
          extra_charges_total: Amt === 0 ? 0 : Amt - x.extra_charges_disc_amt,
        };
      }),
    );
  }

  const handleDeleteExtraCharge = (index: number) => {
    setExtraCharges(prevExtraCharges => {
      const updatedExtraCharges = [...prevExtraCharges];
      updatedExtraCharges.splice(index, 1);
      return updatedExtraCharges;
    });
  };

  const handleTotalExtraCharge = () => {
    let total = 0;
    extraCharges?.forEach(charge => {
      total += charge.extra_charges_total || 0;
    });

    return Number(total).toFixed(2);
  };

  const handleExtraChargeAdd = () => {
    setExtraCharges([
      ...extraCharges,
      {
        extra_charges_no: extraCharges.length + 1,
        extra_charges_title: '',
        extra_charges_distribution_method: 'Proportionately with all installment',
        extra_charges_area: 0,
        extra_charges_rate: 0,
        extra_charges_disc_amt: 0,
        extra_charges_disc_per: 0,
        extra_charges_total: 0,
        extra_charges_base: 0,
      },
    ]);
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const { values, setFieldValue } = formik;

  // govt Taxes
  useEffect(() => {
    const { basic_rate_area = 0, basic_rate = 0, calculation_method } = values;

    const basic_rate_total = basic_rate_area * basic_rate;

    if (calculation_method === 'rate_base') {
      setBaseAmount(basic_rate_total);
    } else if (calculation_method === 'clp_base') {
      setBaseAmount(basic_rate_area * basic_rate);
    } else if (calculation_method === 'fixed_amount') {
      setBaseAmount(basic_rate);
    } else {
      setBaseAmount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.basic_rate_area, values.basic_rate, values.calculation_method]);

  useEffect(() => {
    const { basic_rate_disc_amt = 0 } = values;

    if (
      values.calculation_method === 'rate_base' ||
      values.calculation_method === 'fixed_amount' ||
      values.calculation_method === 'clp_base'
    ) {
      setFieldValue(
        'basic_rate_basic_amount',
        parseFloat((baseAmount - basic_rate_disc_amt).toFixed(2)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseAmount, setFieldValue, values.basic_rate_disc_amt, values.basic_rate_disc_per]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      basic_rate:
        values.calculation_method === 'rate_base'
          ? unitAreaInfo?.rate_base_amt
          : values.calculation_method === 'clp_base'
          ? rate
          : unitAreaInfo?.fixed_amount || 0,
      basic_rate_disc_amt: 0,
      basic_rate_disc_per: 0,
      basic_rate_basic_amount: 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.calculation_method]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
    });
    formik.setFieldValue('basic_rate', rate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const unitInfoValues = useMemo(() => {
    return unitInfo?.booking_unit_sheet_towers_data?.find(
      e => e.project_main_units_id === Number(unit_id),
    );
  }, [unitInfo?.booking_unit_sheet_towers_data, unit_id]);

  const handleDownloadPDF = () => {
    const doc = new jsPDF('landscape', 'mm', 'a4');
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    let yOffset = 10;

    const scalingFactor = 4; // Adjust the scaling factor to improve resolution (if needed)

    const logoUrl = logo;
    const maxWidth = 40;
    const logoImage = new Image();

    logoImage.onload = () => {
      const logoAspectRatio = logoImage.width / logoImage.height;
      let logoWidth = maxWidth;
      let logoHeight = logoWidth / logoAspectRatio;

      const maxHeight = pageHeight / 4;
      if (logoHeight > maxHeight) {
        logoHeight = maxHeight;
        logoWidth = logoHeight * logoAspectRatio;
      }

      // Add Logo to PDF
      doc.addImage(logoUrl, 'PNG', 5, yOffset, logoWidth, logoHeight);
      yOffset += logoHeight + 5;

      // Set Heading for the PDF
      const marginLeft = 7;
      doc.setFontSize(14);
      doc.setFont('nunitosemibold', 'bold');
      doc.setTextColor('#041D36');
      doc.text('Booking Quotation', marginLeft, (yOffset += 5));
      yOffset += 10;

      // Render the Unit Information as text in the PDF
      doc.setFontSize(12);
      doc.setFont('nunitosemibold', 'bold');
      doc.text('Unit Details:', marginLeft, yOffset);
      yOffset += 8;

      doc.setFontSize(10);
      doc.setFont('nunitosemibold', '500');
      doc.text(`Unit Information: ${unitInfoValues?.title || ''}`, marginLeft, yOffset);
      yOffset += 6;
      doc.text(
        `Super Buildup Area: ${unitAreaInfo?.super_build_up_area || ''}`,
        marginLeft,
        yOffset,
      );
      yOffset += 6;
      doc.text(`Terrace Area: ${unitAreaInfo?.terracearea || ''}`, marginLeft, yOffset);
      yOffset += 6;
      doc.text(`Car Parking No: ${formik.values.parking_no || ''}`, marginLeft, yOffset);
      yOffset += 10; // Add some space before Calculation Method

      // Add Calculation Method Heading
      const calculationMethod = formik.values.calculation_method;
      let calculationMethodText = '';
      let tableData = '';

      switch (calculationMethod) {
        case 'rate_base':
          calculationMethodText = 'Rate Based';
          tableData = generateRateBaseTable();
          break;
        case 'fixed_amount':
          calculationMethodText = 'Fixed Amount';
          tableData = generateFixedAmountTable();
          break;
        case 'clp_base':
          calculationMethodText = 'CLP Based';
          tableData = generateClpBaseTable();
          break;
        default:
          calculationMethodText = 'No Calculation Method Selected';
          tableData = '';
      }

      // Add Calculation Method Text to PDF
      doc.setFontSize(12);
      doc.setFont('nunitosemibold', 'bold');
      doc.text(`Calculation Method: ${calculationMethodText}`, marginLeft, yOffset);
      yOffset += 3;

      // Add Calculation Method Table
      // Create a hidden container for tables

      const hiddenContainer = window.document.createElement('div');
      document.body.appendChild(hiddenContainer);
      const tableDivCalculation = window.document.createElement('div');
      tableDivCalculation.innerHTML = tableData; // Add Calculation Method table
      hiddenContainer.appendChild(tableDivCalculation);

      // Use html2canvas to render the Calculation Method table into an image
      html2canvas(tableDivCalculation, {
        scale: scalingFactor,
        useCORS: true,
        logging: false,
        allowTaint: true,
      })
        .then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const imgHeight = (canvas.height * pageWidth) / canvas.width; // Adjust the image height to fit the page width

          // Check if the image fits in the current page, if not, add a new page
          if (yOffset + imgHeight > pageHeight) {
            doc.addPage();
            yOffset = 10;
          }

          // Add the rendered table image (Calculation Method) to the PDF
          doc.addImage(imgData, 'PNG', 0, yOffset, pageWidth, imgHeight);
          yOffset += imgHeight + 5; // Adjust yOffset for the next content

          // Remove the Calculation Method table div
          hiddenContainer.removeChild(tableDivCalculation);
          yOffset += 3;

          // Now handle the Other Charges Table
          doc.setFontSize(12);
          doc.setFont('nunitosemibold', 'bold');
          doc.text('Other Charges:', marginLeft, yOffset); // Add "Other Charges" header text
          yOffset += 3; // Adjust yOffset after the header text

          // Generate the Other Charges Table (HTML content)
          const otherChargesTable = generateOtherChargesTable();

          // Create a hidden div for the Other Charges table and append to the document body
          const tableDivOtherCharges = document.createElement('div');
          tableDivOtherCharges.innerHTML = otherChargesTable;
          hiddenContainer.appendChild(tableDivOtherCharges);

          // Use html2canvas to render the Other Charges table into an image
          html2canvas(tableDivOtherCharges, {
            scale: scalingFactor,
            useCORS: true,
            logging: false,
            allowTaint: true,
          })
            .then(canvas => {
              const imgData = canvas.toDataURL('image/png');
              const imgHeight = (canvas.height * pageWidth) / canvas.width; // Adjust the image height to fit the page width

              // Check if the image fits in the current page, if not, add a new page
              if (yOffset + imgHeight > pageHeight) {
                doc.addPage();
                yOffset = 10;
              }

              // Add the rendered table image (Other Charges) to the PDF
              doc.addImage(imgData, 'PNG', 0, yOffset, pageWidth, imgHeight);
              yOffset += imgHeight + 5; // Adjust yOffset for the next content

              // Remove the Other Charges table div from the document
              hiddenContainer.removeChild(tableDivOtherCharges);
              yOffset += 3;
              // Add "Extra Charges" Text before the Extra Charges Table
              doc.setFontSize(12);
              doc.setFont('nunitosemibold', 'bold');
              doc.text('Extra Charges:', marginLeft, yOffset); // Add "Extra Charges" header text
              yOffset += 3; // Adjust yOffset after the header text

              // Generate the Extra Charges Table (HTML content)
              const extraChargesTable = generateExtraChargesTable(); // Make sure you have this function

              // Create a hidden div for the Extra Charges table and append to the document body
              const tableDivExtraCharges = document.createElement('div');
              tableDivExtraCharges.innerHTML = extraChargesTable;
              hiddenContainer.appendChild(tableDivExtraCharges);

              // Use html2canvas to render the Extra Charges table into an image
              html2canvas(tableDivExtraCharges, {
                scale: scalingFactor,
                useCORS: true,
                logging: false,
                allowTaint: true,
              })
                .then(canvas => {
                  const imgData = canvas.toDataURL('image/png');
                  const imgHeight = (canvas.height * pageWidth) / canvas.width; // Adjust the image height to fit the page width

                  // Check if the image fits in the current page, if not, add a new page
                  if (yOffset + imgHeight > pageHeight) {
                    doc.addPage();
                    yOffset = 10;
                  }

                  // Add the rendered table image (Extra Charges) to the PDF
                  doc.addImage(imgData, 'PNG', 0, yOffset, pageWidth, imgHeight);
                  yOffset += imgHeight + 5; // Adjust yOffset for the next content

                  // Remove the Extra Charges table div from the document
                  hiddenContainer.removeChild(tableDivExtraCharges);
                  yOffset += 3;

                  const governmentTaxesHTML = generateGovernmentTaxesAndSummaryTable();

                  const tableDivGovTaxes = document.createElement('div');
                  // tableDivGovTaxes.style.visibility = 'hidden';
                  tableDivGovTaxes.innerHTML = governmentTaxesHTML;
                  hiddenContainer.appendChild(tableDivGovTaxes);

                  html2canvas(tableDivGovTaxes, {
                    scale: scalingFactor,
                    useCORS: true,
                    logging: false,
                    allowTaint: true,
                  })
                    .then(canvas => {
                      const imgData = canvas.toDataURL('image/png');
                      const imgHeight = (canvas.height * pageWidth) / canvas.width; // Adjust the image height to fit the page width

                      // Check if the image fits in the current page, if not, add a new page
                      if (yOffset + imgHeight > pageHeight) {
                        doc.addPage();
                        yOffset = 10;
                      }

                      // Add the rendered table image (Government Taxes and Summary) to the PDF
                      doc.addImage(imgData, 'PNG', 0, yOffset, pageWidth, imgHeight);
                      yOffset += imgHeight + 5; // Adjust yOffset for the next content

                      // Remove the Government Taxes table div
                      hiddenContainer.removeChild(tableDivGovTaxes);
                      yOffset += 3;

                      const installmentMethod = formik.values.installment_type;
                      let installmentMethodText = '';
                      let installmentTableData = '';

                      switch (installmentMethod) {
                        case 'clp_installments':
                          installmentMethodText = 'Construction Link Plan';
                          installmentTableData = generateCLPTable();
                          break;
                        case 'coustom_installments':
                          installmentMethodText = 'Custom Installments';
                          installmentTableData = generateCITable();
                          break;
                        default:
                          installmentMethodText = 'No Installment Method Selected';
                          installmentTableData = '';
                      }

                      // Add Calculation Method Text to PDF
                      doc.setFontSize(12);
                      doc.setFont('nunitosemibold', 'bold');
                      doc.text(`Payment Schedule: ${installmentMethodText}`, marginLeft, yOffset);
                      yOffset += 3;

                      // Add Calculation Method Table
                      const tableDivInstallments = document.createElement('div');
                      tableDivInstallments.innerHTML = installmentTableData; // Add Calculation Method table
                      hiddenContainer.appendChild(tableDivInstallments);

                      // Use html2canvas to render the Calculation Method table into an image
                      html2canvas(tableDivInstallments, {
                        scale: scalingFactor,
                        useCORS: true,
                        logging: false,
                        allowTaint: true,
                      })
                        .then(canvas => {
                          const imgData = canvas.toDataURL('image/png');
                          const imgHeight = (canvas.height * pageWidth) / canvas.width; // Adjust the image height to fit the page width

                          // Check if the image fits in the current page, if not, add a new page
                          if (yOffset + imgHeight > pageHeight) {
                            doc.addPage();
                            yOffset = 10;
                          }

                          // Add the rendered table image (Calculation Method) to the PDF
                          doc.addImage(imgData, 'PNG', 0, yOffset, pageWidth, imgHeight);
                          yOffset += imgHeight + 5; // Adjust yOffset for the next content

                          // Remove the Calculation Method table div
                          hiddenContainer.removeChild(tableDivInstallments);
                          yOffset += 3;

                          document.body.removeChild(hiddenContainer);
                          // Save the PDF
                          doc.save('Booking-Quotation.pdf');
                        })
                        .catch(error => {
                          console.error('Error generating Payment Installment table:', error);
                        });
                    })
                    .catch(error => {
                      console.error('Error generating Government Taxes table:', error);
                    });
                })

                .catch(error => {
                  console.error('Error generating Extra Charges table:', error);
                });
            })
            .catch(error => {
              console.error('Error generating Other Charges table:', error);
            });
        })
        .catch(error => {
          console.error('Error generating Calculation Method table:', error);
        });
    };

    logoImage.src = logoUrl;
  };

  const generateGovernmentTaxesAndSummaryTable = () => {
    return `
      <div style="display: flex; justify-content: space-between; padding:0 50px;">
        <!-- Government Taxes Section (Left Side) -->
        <div style="width: 48%; margin-right: 10px; float: left; box-sizing: border-box;">
          <h3>Government Taxes</h3>
          <p style="margin-top:10px;"><strong>Sub Total Amount:</strong> ₹ ${(
            Number(values.basic_rate_basic_amount) + parseFloat(handleTotalOtherCharge())
          ).toFixed(2)}</p>
          <p><strong>GST (${values.gst_per}%):</strong> ₹ ${values.gst_amt}</p>
          <p><strong>Stamp Duty (${values.stampduty_per}%):</strong> ₹ ${
      values.stampduty_amount
    }</p>
          <p><strong>Registration (${values.reg_per}%):</strong> ₹ ${values.reg_amount}</p>
          <p  class="total-background" style="padding-left:10px;"><strong>Taxes Total:</strong> ₹ ${(
            Number(values.gst_amt) +
            Number(values.stampduty_amount) +
            Number(values.reg_amount)
          ).toFixed(2)}</p>
        </div>
  
        <!-- Summary Section (Right Side) -->
      <div style="width: 48%; float: left; box-sizing: border-box;">
          <h3>Summary</h3>
          <p style="margin-top:10px;"><strong>Basic Amount:</strong> ₹ ${Number(
            values.basic_rate_basic_amount,
          ).toFixed(2)}</p>
          <p><strong>Other Charges Total:</strong> ₹ ${
            values.calculation_method ? handleTotalOtherCharge() : '0.00'
          }</p>
          <p><strong>Government Taxes Total:</strong> ₹ ${(
            Number(values.gst_amt) +
            Number(values.stampduty_amount) +
            Number(values.reg_amount)
          ).toFixed(2)}</p>
          <p><strong>Extra Charges:</strong> ₹ ${
            values.calculation_method ? handleTotalExtraCharge() : '0.00'
          }</p>
          <p class="total-background" style="padding-left:10px;"><strong>Property Final Amount:</strong> ₹ ${(
            Number(values.basic_rate_basic_amount) +
            parseFloat(handleTotalOtherCharge()) +
            Number(values.gst_amt) +
            Number(values.stampduty_amount) +
            Number(values.reg_amount) +
            parseFloat(handleTotalExtraCharge())
          ).toFixed(2)}  
        </div>
      </div>
    `;
  };

  // Function to generate the rate-based table
  const generateRateBaseTable = () => {
    return `
      <table border="1"  style="width: calc(100% - 50px); border-collapse: collapse; margin: 0 20px; box-sizing: border-box;">
        <thead>
          <tr>
            <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Sr No</th>
            <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Description</th>
            <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Area</th>
            <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Rate</th>
            <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Basic Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">01</td>
            <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">Basic rate of unit</td>
            <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${formik.values.basic_rate_area}</td>
            <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${formik.values.basic_rate}</td>
            <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${formik.values.basic_rate_basic_amount}</td>
          </tr>
        </tbody>
      </table>
    `;
  };

  // Function to generate the fixed amount table
  const generateFixedAmountTable = () => {
    return `
      <table border="1" style="width: calc(100% - 50px); border-collapse: collapse; margin: 0 20px; box-sizing: border-box;">
        <thead>
          <tr>
            <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Sr No</th>
            <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Description</th>
            <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Rate</th>
            <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Basic Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">01</td>
            <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">Basic rate of unit</td>
            <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${formik.values.basic_rate}</td>
            <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${formik.values.basic_rate_basic_amount}</td>
          </tr>
        </tbody>
      </table>
    `;
  };

  // Function to generate the CLP-based table
  const generateClpBaseTable = () => {
    return `
      <table border="1" style="width: calc(100% - 50px); border-collapse: collapse; margin: 0 20px; box-sizing: border-box;">
        <thead>
          <tr>
            <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Sr No</th>
            <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Description</th>
            <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Area</th>
            <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Rate</th>
            <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Basic Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">01</td>
            <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">Basic rate of unit</td>
            <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${formik.values.basic_rate_area}</td>
            <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${formik.values.basic_rate}</td>
            <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${formik.values.basic_rate_basic_amount}</td>
          </tr>
        </tbody>
      </table>
    `;
  };
  const generateOtherChargesTable = () => {
    // Generate table content
    const headerRow = `
      <tr>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Sr No</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Title</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Distribution Method</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Area</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Rate</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Amount</th>
      </tr>
    `;

    const tableRows = OCList?.map(x => {
      return `
        <tr>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x.other_charges_no
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x.other_charges_title
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x.other_charges_distribution_method
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x.amount_type === 'ratebase_amt' ? x.other_charges_area : ''
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x.other_charges_rate
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x.other_charges_amount || 0
          }</td>
        </tr>
      `;
    }).join('');

    const totalRow = `
      <tr>
        <td class="text-right" colSpan="6">
          <div class="total-background">
            <label style="font-size: 1.1rem;">
              <span style="margin-right: 6px;">Total</span>
              <span style="margin-right: 6px;">:</span>
              <span> ₹ </span>
              <span>${handleTotalOtherCharge()}</span>
            </label>
          </div>
        </td>
      </tr>
    `;

    // Return the complete table as a string
    return `
       <table border="1"  style="width: calc(100% - 50px); border-collapse: collapse; margin: 0 20px; box-sizing: border-box;">
        <thead>
          ${headerRow}
        </thead>
        <tbody>
          ${tableRows}
          ${totalRow}
        </tbody>
      </table>
    `;
  };
  const generateExtraChargesTable = () => {
    // Generate table content
    const headerRow = `
      <tr>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Sr No</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Title</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Distribution Method</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Area</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Rate</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Amount</th>
      </tr>
    `;

    const tableRows = extraCharges
      ?.map(x => {
        return `
        <tr>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x.extra_charges_no
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x.extra_charges_title
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x.extra_charges_distribution_method
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x.amount_type === 'ratebase_amt' ? x.extra_charges_area : ''
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x.extra_charges_rate
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x.extra_charges_total || 0
          }</td>
        </tr>
      `;
      })
      .join('');

    const totalRow = `
      <tr>
        <td class="text-right" colSpan="6">
          <div class="total-background">
            <label style="font-size: 1.1rem;">
              <span style="margin-right: 6px;">Total</span>
              <span style="margin-right: 6px;">:</span>
              <span> ₹ </span>
              <span>${handleTotalExtraCharge()}</span>
            </label>
          </div>
        </td>
      </tr>
    `;

    // Return the complete table as a string
    return `
       <table border="1"  style="width: calc(100% - 50px); border-collapse: collapse; margin: 0 20px; box-sizing: border-box;">
        <thead>
          ${headerRow}
        </thead>
        <tbody>
          ${tableRows}
          ${totalRow}
        </tbody>
      </table>
    `;
  };
  const generateCLPTable = () => {
    // Generate table content
    const headerRow = `
      <tr>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Sr No</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Installment Name</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">%</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Basic Amount</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Other Charges Amount</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">GST</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Extra Charges Amount	</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Installment Amount</th>
      </tr>
    `;

    const tableRows = values?.clp_installments
      ?.map((x, i) => {
        return `
        <tr>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            i + 1
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x.clp_payment_installment
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x?.clp_per !== undefined ? x?.clp_per : ''
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x?.clp_basic_amt !== 0 ? x?.clp_basic_amt.toFixed(2) : '0.00'
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x?.clp_otherchages_amt !== 0
              ? x?.clp_otherchages_amt.toFixed(2)
              : x?.clp_otherchages_amt.toFixed(2)
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x?.clp_gst !== 0 ? x?.clp_gst.toFixed(2) : x?.clp_gst.toFixed(2)
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x?.clp_extrachages_amt !== 0
              ? x?.clp_extrachages_amt.toFixed(2)
              : x?.clp_extrachages_amt.toFixed(2)
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x?.clp_installment_amount !== 0
              ? x?.clp_installment_amount.toFixed(2)
              : x?.clp_installment_amount.toFixed(2)
          }</td>
        </tr>
      `;
      })
      .join('');

    const totalRow = `
      <tr>
        <td class="text-right" colSpan="9">
          <div class="total-background">
            <label style="font-size: 1.1rem;">
              <span style="margin-right: 6px;">Total</span>
              <span style="margin-right: 6px;">:</span>
              <span> ₹ </span>
              <span>${values?.custom_payment_total_amount}</span>
            </label>
          </div>
        </td>
      </tr>
    `;

    // Return the complete table as a string
    return `
       <table border="1"  style="width: calc(100% - 50px); border-collapse: collapse; margin: 0 20px; box-sizing: border-box;">
        <thead>
          ${headerRow}
        </thead>
        <tbody>
          ${tableRows}
          ${totalRow}
        </tbody>
      </table>
    `;
  };
  const generateCITable = () => {
    // Generate table content
    const headerRow = `
      <tr>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Sr No</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Installment Name</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">%</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Basic Amount</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Other Charges Amount</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">GST</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Extra Charges Amount	</th>
        <th style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;">Installment Amount</th>
      </tr>
    `;

    const tableRows = values.installments
      ?.map((x, i) => {
        return `
        <tr>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            i + 1
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x.custom_payment_installment
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x.installment_per !== undefined ? x.installment_per : ''
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x?.installment_basic_amt !== 0 ? x?.installment_basic_amt : '0.00'
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x?.installment_otherchages_amt !== 0
              ? x?.installment_otherchages_amt.toFixed(2)
              : x?.installment_otherchages_amt.toFixed(2)
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x?.gst !== 0 ? x?.gst.toFixed(2) : x?.gst.toFixed(2)
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x?.installment_extrachages_amt !== 0
              ? x?.installment_extrachages_amt.toFixed(2)
              : x?.installment_extrachages_amt.toFixed(2)
          }</td>
          <td style="text-align: center; padding-left: 10px; padding-right: 10px; font-weight: bold;font-size: 15px">${
            x?.installment_amount !== 0
              ? x?.installment_amount.toFixed(2)
              : x?.installment_amount.toFixed(2)
          }</td>
        </tr>
      `;
      })
      .join('');

    const totalRow = `
      <tr>
        <td class="text-right" colSpan="9">
          <div class="total-background">
            <label style="font-size: 1.1rem;">
              <span style="margin-right: 6px;">Total</span>
              <span style="margin-right: 6px;">:</span>
              <span> ₹ </span>
              <span>${values?.custom_payment_total_amount}</span>
            </label>
          </div>
        </td>
      </tr>
    `;

    // Return the complete table as a string
    return `
       <table border="1"  style="width: calc(100% - 50px); border-collapse: collapse; margin: 0 20px; box-sizing: border-box;">
        <thead>
          ${headerRow}
        </thead>
        <tbody>
          ${tableRows}
          ${totalRow}
        </tbody>
      </table>
    `;
  };
  return (
    <>
      <Loader loading={loading} />

      {/* Header */}

      <BookingHeader
        // ref={bookingHeaderRef}
        formikProps={formik}
        handleDownloadPDF={handleDownloadPDF}
        navigate={navigate}
      />
      <Box id="pdf-content">
        <section className="booking-form-sec pt-0 bookingFormUpdated">
          <Box className="booking-form-row">
            <UnitInfo ref={unitInfoRef} formikProps={formik} unit_id={unit_id} />

            <CalculationMethod
              ref={calculationMethodRef}
              baseAmount={baseAmount}
              formikProps={formik}
              unitAreaInfo={unitAreaInfo}
            />

            <OtherCharges
              ref={otherChargesRef}
              formikProps={formik}
              handleOCListChange={handleOCListChange}
              handleTotalOtherCharge={handleTotalOtherCharge}
              OCList={OCList}
            />

            <ExtraCharges
              ref={extraChargesRef}
              extraCharges={extraCharges}
              formikProps={formik}
              handle_Extra_Charge_Row_Total={handle_Extra_Charge_Row_Total}
              handleDeleteExtraCharge={handleDeleteExtraCharge}
              handleExtraChargeAdd={handleExtraChargeAdd}
              handleTotalExtraCharge={handleTotalExtraCharge}
              handleUpdateExtraCharge={handleUpdateExtraCharge}
            />
            <Box style={{ display: 'flex', gap: 5 }}>
              <GovtTaxes
                ref={goverTaxesRef}
                formikProps={formik}
                handleTotalOtherCharge={handleTotalOtherCharge}
              />

              <Summary
                ref={summaryRef}
                formikProps={formik}
                handleTotalExtraCharge={handleTotalExtraCharge}
                handleTotalOtherCharge={handleTotalOtherCharge}
                handleTotalOtherDiscountAmt={handleTotalOtherDiscountAmt}
              />
            </Box>

            <InstallmentSelection
              ref={installmentsRef}
              extraCharges={extraCharges}
              formikProps={formik}
              handleTotalExtraCharge={handleTotalExtraCharge}
              handleTotalOtherCharge={handleTotalOtherCharge}
              handleTotalOtherDiscountAmt={handleTotalOtherDiscountAmt}
              OCList={OCList}
              project_id={project_id}
              tower_id={tower_id}
            />
          </Box>
        </section>
      </Box>
    </>
  );
};

export default UE_BookingForm_2;
