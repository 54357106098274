import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FormikProps } from 'formik';
import Cookies from 'js-cookie';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { getCLPInstallmentOptions } from 'redux/sales';
import { useAppDispatch, useAppSelector } from 'redux/store';
const Label = styled(Typography)`
  color: #041d36;
  font-weight: 400;
  font-size: 15;
`;
// const project_id = 19;
// const tower_id = 1;

interface CalculationMethodProps {
  formikProps: FormikProps<any>; // Replace `any` with the type of your form values if needed
  baseAmount: number;
  unitAreaInfo: any;
}

const CalculationMethod = React.forwardRef<HTMLDivElement, CalculationMethodProps>((props, ref) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { formikProps, unitAreaInfo } = props;

  const project_id = Number(Cookies.get('_hjproject_idUser_2492391'));
  // const unit_id = Cookies.get('_hjunit_idUser_2492391');
  const tower_id = Number(Cookies.get('_hjtower_idUser_2492391'));

  const { values, handleBlur, setFieldValue, setValues } = formikProps;
  const rate = location.state ? location.state.rate : undefined;

  // redux state values
  const { CLPInstallmentList: installmentsList } = useAppSelector(s => s.sales);

  useEffect(() => {
    dispatch(getCLPInstallmentOptions({ project_id, tower_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setValues({
      ...values,
      basic_rate:
        values.calculation_method === 'rate_base'
          ? unitAreaInfo?.rate_base_amt
          : values.calculation_method === 'clp_base'
          ? rate
          : unitAreaInfo?.fixed_amount || 0,
      basic_rate_disc_amt: 0,
      basic_rate_disc_per: 0,
      basic_rate_basic_amount: 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.calculation_method]);

  // const resetGovTaxes = () => {
  //   setFieldValue('gst_amt', 0);
  //   setFieldValue('stampduty_amount', 0);
  //   setFieldValue('reg_amount', 0);
  //   setFieldValue('gst_per', 0);
  //   setFieldValue('stampduty_per', 0);
  //   setFieldValue('reg_per', 0);
  // };

  // installment Options
  const installmentOptions = useMemo(() => {
    return installmentsList?.map(e => ({
      label: e.rate || 0,
      value: e.id,
    }));
  }, [installmentsList]);

  return (
    <div ref={ref} className="units-booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <h5>Rate Calculation</h5>
        <div className="form-row ml-2">
          <div className="col-md-8 form-col-gap">
            <div className="row w-100 align-items-center">
              <p className="mb-0 mr-4">
                <Label>Calculation method :</Label>
              </p>
              <RadioGroup row aria-labelledby="installments-selection" name="installment-selection">
                <FormControlLabel
                  checked={values?.calculation_method === 'rate_base'}
                  className="mb-0 mr-5"
                  control={
                    <Radio
                      onClick={() => {
                        setFieldValue('calculation_method', 'rate_base');
                      }}
                    />
                  }
                  label="Rate Based"
                  sx={{
                    '& .MuiFormControlLabel-root,& .MuiFormControlLabel-label': {
                      fontSize: '15px',
                    },
                  }}
                  value="rate_base"
                />
                <FormControlLabel
                  checked={values?.calculation_method === 'fixed_amount'}
                  className="mb-0 mr-5"
                  control={
                    <Radio
                      onClick={() => {
                        setFieldValue('calculation_method', 'fixed_amount');
                      }}
                    />
                  }
                  label="Fixed Amount"
                  sx={{
                    '& .MuiFormControlLabel-root,& .MuiFormControlLabel-label': {
                      fontSize: '15px',
                    },
                  }}
                  value="fixed_amount"
                />
                <FormControlLabel
                  checked={values?.calculation_method === 'clp_base'}
                  className="mb-0 mr-5"
                  control={
                    <Radio
                      onClick={() => {
                        setFieldValue('calculation_method', 'clp_base');
                      }}
                    />
                  }
                  label="CLP Based"
                  sx={{
                    '& .MuiFormControlLabel-root,& .MuiFormControlLabel-label': {
                      fontSize: '15px',
                    },
                  }}
                  value="clp_base"
                />
              </RadioGroup>
            </div>
          </div>
        </div>
        {values.calculation_method === 'rate_base' ? (
          <div>
            {/* Rate Based */}
            <table className="table">
              <thead>
                <th>Sr No</th>
                <th>Description</th>

                <th>Area</th>
                <th className="text-right">Rate</th>
                <th className="text-right">Basic Amount</th>
              </thead>
              <tbody>
                <tr>
                  <td>01</td>
                  <td>Basic rate of unit</td>
                  {/* rate_super_buildup */}
                  <td>
                    <input
                      readOnly
                      className="form-control"
                      name="basic_rate_area"
                      type="number"
                      value={values?.basic_rate_area}
                      onBlur={handleBlur}
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      name="basic_rate"
                      style={{ textAlign: 'right' }}
                      type="number"
                      value={values.basic_rate}
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('basic_rate', e.target.value);
                        setFieldValue('basic_rate_disc_amt', 0);
                        setFieldValue('basic_rate_disc_per', 0);
                        // resetGovTaxes();
                      }}
                    />
                  </td>
                  <td className="text-right">
                    {Number(values.basic_rate_basic_amount).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : values.calculation_method === 'fixed_amount' ? (
          <div>
            {/* Fixed Amount Based */}
            <table className="table">
              <thead>
                <th>Sr No</th>
                <th>Description</th>
                <th className="text-right">Rate</th>
                <th className="text-right">Basic Amount</th>
              </thead>
              <tbody>
                <tr>
                  <td>01</td>
                  <td>Basic rate of unit</td>
                  <td>
                    <input
                      className="form-control"
                      name="basic_rate"
                      placeholder="Amount"
                      style={{ textAlign: 'right' }}
                      type="number"
                      value={values.basic_rate < 0 ? 0 : values.basic_rate}
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('basic_rate', e.target.value);
                        setFieldValue('basic_rate_disc_amt', 0);
                        setFieldValue('basic_rate_disc_per', 0);
                        // resetGovTaxes();
                      }}
                    />
                  </td>
                  <td className="text-right">
                    {values.basic_rate_basic_amount < 0 ? 0 : values.basic_rate_basic_amount}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : values.calculation_method === 'clp_base' ? (
          <div>
            {/* CLP Based */}
            <table className="table">
              <thead>
                <th>Sr No</th>
                <th>Description</th>

                <th>Area</th>
                <th className="text-right">Rate</th>
                <th className="text-right">Basic Amount</th>
              </thead>
              <tbody>
                <tr>
                  <td>01</td>
                  <td>Basic rate of unit</td>
                  {/* rate_super_buildup */}
                  <td>
                    <input
                      readOnly
                      className="form-control"
                      name="basic_rate_area"
                      type="number"
                      value={values?.basic_rate_area}
                      onBlur={handleBlur}
                    />
                  </td>
                  <td>
                    <Select
                      closeMenuOnSelect={true}
                      defaultValue={
                        rate !== null
                          ? installmentOptions.find(option => option.label === rate)
                          : { label: 0, value: 0 }
                      }
                      name="basic_rate"
                      options={installmentOptions}
                      styles={{
                        container: base => ({
                          ...base,
                        }),
                      }}
                      // value={values.rate}
                      onChange={e => {
                        setFieldValue('basic_rate', e.label);
                      }}
                    />
                  </td>
                  <td className="text-right">
                    {Number(values.basic_rate_basic_amount).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : undefined}
      </div>
    </div>
  );
});

export default CalculationMethod;
