import './CreditNotes.css';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TimelineConnector, TimelineContent, TimelineSeparator } from '@mui/lab';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import {
  Accordion,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  styled,
  Table,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Drawer from '@mui/material/Drawer';
import { ReactComponent as Group } from 'assets/images/Group.svg';
import Loader from 'components/atoms/Loader';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  deleteBill,
  deleteCreditNots,
  getCreditNotesDetails,
  getCreditNotsLogs,
} from 'redux/materials';
import { useAppDispatch, useAppSelector } from 'redux/store';
import theme from 'Theme';
import { getPermissions } from 'utils';
import { PERMISSIONS_BASE_STRUCTURE } from 'utils/constant';

import CreditRow from './Components/CreditRow';

const ApplyButton = styled(Button)`
  && {
    background-color: #edf1fe;
    color: #4872f4;
    border-radius: 12px;
    margin-right: 10px;
    padding: 11px 24px 11px 24px;
    text-transform: none;
    &:hover {
      background: edf1fe;
    }
  }
`;

const HistoryButton = styled(Button)`
  && {
    background-color: #edf1fe;
    color: #4872f4;
    border-radius: 12px;
    padding: 11px 24px 11px 24px;
    text-transform: none;
  }
`;

const HeadingFont = styled(Typography)`
  && {
    font-size: 28px;
    weight: 600;
  }
`;

const DrawerFont = styled(Typography)`
  && {
    font-size: 20px;
  }
`;

const CardHeaderFont = styled(Typography)`
  && {
    font-size: 22px;
    weight: 600;
    color: #3d3f43;
  }
`;

const DetailsHeaderFont = styled(Typography)`
  && {
    font-size: 18px;
    weight: 400;
    color: #3d3f43;
  }
`;

const DetailsFont = styled(Typography)`
  && {
    font-size: 18px;
    weight: 500;
    color: #041d36;
  }
`;

const TimelineCustomContent = styled(TimelineContent)`
  && {
    font-size: 20px;
  }
`;

const StepperIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  boxShadow: '0 0 0 2px rgb(16 22 26 / 40%)',
}));

const StepperActiveIcon = styled(StepperIcon)({
  backgroundColor: '#fff',
  '&:before': {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#2160E1,#2160E1 28%,transparent 32%)',
    content: '""',
  },
});

const StyleTableCell = styled(TableCell)(() => {
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'unset',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '4px',
      position: 'relative',
      '&:not(&:nth-child(1) )': {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 20,
          left: 0,
          bottom: 20,
          width: '1px',
          background: `${theme.palette.grey[300]}`,
        },
      },
    },
    [`&.${tableCellClasses.body}`]: {
      border: '0px 0px 2px 0px',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      position: 'relative',
    },
  };
});
const EditBtn = styled(Button)`
  && {
    background-color: #edf1fe;
    color: #4872f4;
    border-radius: 12px;
    margin-right: 10px;
    padding: 11px 24px 11px 24px;
    text-transform: none;
    &:hover {
      background: edf1fe;
    }
  }
`;
const DeleteBtn = styled(Button)`
  background: rgba(255, 93, 93, 0.2);
  color: #ff5d5d;
  box-shadow: none;
  text-transform: none;
  border-radius: 12px;
  margin-right: 10px;
  padding: 11px 24px 11px 24px;
  text-transform: none;
  &:hover {
    background: rgba(255, 93, 93, 0.2);
  }
`;

const BillCreditedAccordion = ({
  billCreditData,
  project_id,
  credit_notes_id,
  loadData,
  modulePermission,
  isProjectAdmin,
}) => {
  const billCredited = billCreditData?.credit_notes_bills || [];
  const dispatch = useAppDispatch();

  if (billCredited.length === 0) {
    return null;
  }

  const handleRemoveCreditBill = async (invoice_id, credit_bill_id) => {
    await dispatch(
      deleteBill({
        project_id,
        credit_notes_id,
        invoice_id,
        credit_bill_id,
      }),
    );

    loadData();
  };

  return (
    <Accordion
      component={Paper}
      style={{
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 25px',
        boxShadow: 'none',
      }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<ExpandMoreIcon />}
        id="panel1a-header"
      >
        <Typography sx={{ fontSize: '22px', color: '#3D3F43' }}>Bill Credited</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table
            aria-label="simple table"
            sx={{ minWidth: 650, border: '1px solid #DEE1E7', borderRadius: '4px' }}
          >
            <TableHead>
              <TableRow>
                <StyleTableCell>
                  <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>Date</Typography>
                </StyleTableCell>
                <StyleTableCell sx={{ fontWeight: '500', fontSize: '18px' }}>
                  <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>Invoice No</Typography>
                </StyleTableCell>
                <StyleTableCell sx={{ fontWeight: '500', fontSize: '18px' }}>
                  <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>Bill Amt.</Typography>
                </StyleTableCell>
                <StyleTableCell>
                  <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>
                    Amount Credited
                  </Typography>
                </StyleTableCell>
                {modulePermission?.admin || isProjectAdmin ? (
                  <StyleTableCell>
                    <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>Actions</Typography>
                  </StyleTableCell>
                ) : null}
              </TableRow>
            </TableHead>
            {billCredited?.map((item, index) => {
              return (
                <CreditRow
                  key={index}
                  bill={item}
                  index={index}
                  isProjectAdmin={isProjectAdmin}
                  modulePermission={modulePermission}
                  RemoveCreditBill={handleRemoveCreditBill}
                />
              );
            })}
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

const SupplierDetails = ({ supplierDetailsData }) => {
  const {
    supplier_name,
    supplier_phone,
    company_address,
    created,
    credit_note,
    invoice_no,
    credit_remaining,
    supplier_email,
    sub_total,
    total,
    discount_amount,
    description,
    discount_percent,
  } = supplierDetailsData?.credit_notes || {};

  const materialDetails = supplierDetailsData?.credit_notes_materials || [];
  const attachmentsDetails = supplierDetailsData?.credit_notes_attachements || [];

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0);

  const onClickHandlerClose = () => {
    setIsOpen(false);
  };

  const onImageClick = index => {
    setphotoIndex(index);
    setIsOpen(true);
  };

  return (
    <Paper elevation={0} style={{ marginTop: 15, marginLeft: 20 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '12px 25px' }}>
        {/* Supplier details */}
        <div style={styles.wrapper}>
          <CardHeaderFont>Supplier Details</CardHeaderFont>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <div
            className="preview-details"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              padding: '24px 32px 15px 15px',
              flexDirection: 'column',
              gap: '15px',
              flexWrap: 'wrap',
            }}
          >
            <div className="suppDetailsHeader">
              <DetailsHeaderFont className="detailsHeaderWidth">Supplier Name</DetailsHeaderFont>
              <div className="suppDetailsData">
                <DetailsFont>:</DetailsFont>
                <DetailsFont>{supplier_name || '-'}</DetailsFont>
              </div>
            </div>
            <div className="suppDetailsHeader">
              <DetailsHeaderFont className="detailsHeaderWidth">Phone Number</DetailsHeaderFont>
              <div className="suppDetailsData">
                <DetailsFont>:</DetailsFont>
                <DetailsFont>+91{supplier_phone || '-'}</DetailsFont>
              </div>
            </div>
            <div className="suppDetailsHeader">
              <DetailsHeaderFont className="detailsHeaderWidth">Company Address</DetailsHeaderFont>
              <div className="suppDetailsData">
                <DetailsFont>:</DetailsFont>
                <DetailsFont>{company_address || '-'}</DetailsFont>
              </div>
            </div>
            <div className="suppDetailsHeader">
              <DetailsHeaderFont className="detailsHeaderWidth">Dates</DetailsHeaderFont>
              <div className="suppDetailsData">
                <DetailsFont>:</DetailsFont>
                <DetailsFont>{dayjs(created).format('DD-MMM-YYYY hh:mm:ss') || '-'}</DetailsFont>
              </div>
            </div>
          </div>
          <div
            className="preview-details"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              padding: '24px 32px 15px 15px',
              marginLeft: '100px',
              flexDirection: 'column',
              gap: '15px',
              flexWrap: 'wrap',
            }}
          >
            <div className="suppDetailsHeader">
              <DetailsHeaderFont className="detailsHeaderWidth">Credit Notes</DetailsHeaderFont>
              <div className="suppDetailsData">
                <DetailsFont>:</DetailsFont>
                <DetailsFont>{credit_note || '-'}</DetailsFont>
              </div>
            </div>
            <div className="suppDetailsHeader">
              <DetailsHeaderFont className="detailsHeaderWidth">Invoice Number</DetailsHeaderFont>
              <div className="suppDetailsData">
                <DetailsFont>:</DetailsFont>
                <DetailsFont>{invoice_no || '-'}</DetailsFont>
              </div>
            </div>
            <div className="suppDetailsHeader">
              <DetailsHeaderFont className="detailsHeaderWidth">Credit Remaining</DetailsHeaderFont>
              <div className="suppDetailsData">
                <DetailsFont>:</DetailsFont>
                <DetailsFont>{`₹ ${credit_remaining || 0}`}</DetailsFont>
              </div>
            </div>
            <div className="suppDetailsHeader">
              <DetailsHeaderFont className="detailsHeaderWidth">Email</DetailsHeaderFont>
              <div className="suppDetailsData">
                <DetailsFont>:</DetailsFont>
                <DetailsFont>{supplier_email || '-'}</DetailsFont>
              </div>
            </div>
            <div className="suppDetailsHeader">
              <DetailsHeaderFont className="detailsHeaderWidth">Reference Number</DetailsHeaderFont>
              <div className="suppDetailsData">
                <DetailsFont>:</DetailsFont>
                <DetailsFont>--</DetailsFont>
              </div>
            </div>
          </div>
        </div>
        <Divider sx={{ mb: 2 }} />
        {/* Materials Details */}
        <div>
          <TableContainer>
            <Table
              aria-label="simple table"
              sx={{ minWidth: 650, border: '1px solid #DEE1E7', borderRadius: '4px' }}
            >
              <TableHead>
                <TableRow>
                  <StyleTableCell align="left">
                    <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>Material</Typography>
                  </StyleTableCell>
                  <StyleTableCell
                    align="left"
                    sx={{ width: '487px', fontWeight: '500', fontSize: '18px' }}
                  >
                    <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>
                      Sub Material
                    </Typography>
                  </StyleTableCell>
                  <StyleTableCell align="right">
                    <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>Unit</Typography>
                  </StyleTableCell>
                  <StyleTableCell align="right">
                    <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>Quantity</Typography>
                  </StyleTableCell>
                  <StyleTableCell align="right">
                    <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>Rate</Typography>
                  </StyleTableCell>
                  <StyleTableCell align="right">
                    <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>
                      Total Amount
                    </Typography>
                  </StyleTableCell>
                </TableRow>
                {materialDetails?.map((materialDetail, index) => (
                  <TableRow key={index}>
                    <StyleTableCell align="left">
                      <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>
                        {materialDetail.mateial_category || '-'}
                      </Typography>
                    </StyleTableCell>
                    <StyleTableCell align="left">
                      <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>
                        {materialDetail.material_sub_category || '-'}
                      </Typography>
                    </StyleTableCell>
                    <StyleTableCell align="right">
                      <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>
                        {materialDetail.material_unit || '-'}
                      </Typography>
                    </StyleTableCell>
                    <StyleTableCell align="right">
                      <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>
                        {materialDetail.quantity || '-'}
                      </Typography>
                    </StyleTableCell>
                    <StyleTableCell align="right">
                      <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>
                        {materialDetail.rate || '-'}
                      </Typography>
                    </StyleTableCell>
                    <StyleTableCell align="right">
                      <Typography sx={{ fontWeight: '500', fontSize: '18px' }}>
                        {materialDetail.total_amount || '-'}
                      </Typography>
                    </StyleTableCell>
                  </TableRow>
                ))}
              </TableHead>
            </Table>
          </TableContainer>
        </div>
        <Divider sx={{ mt: 2, mb: 2 }} />
        {/* Sub Total Details */}
        <div style={{ width: '500px' }}>
          <div style={{ display: 'flex', gap: '100px', marginTop: '20px', marginLeft: '15px' }}>
            <Typography sx={{ color: '#3D3F43', fontSize: '18px' }}>Sub Total</Typography>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <Typography sx={{ color: '#3D3F43', fontSize: '18px' }}>:</Typography>
              <Typography sx={{ color: '#3D3F43', fontSize: '18px' }}>
                ₹{sub_total || '-'}
              </Typography>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '103px', marginTop: '30px', marginLeft: '15px' }}>
            <DetailsFont>Discount</DetailsFont>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <DetailsFont>:</DetailsFont>
              <DetailsFont>
                ₹{`${discount_amount || 0} `}{' '}
                <span className="muted-text" style={{ fontSize: '14px' }}>
                  {`(${discount_percent || 0} %)`}{' '}
                </span>
              </DetailsFont>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '135px', marginTop: '20px', marginLeft: '15px' }}>
            <Typography sx={{ color: '#3D3F43', fontSize: '18px' }}>Total</Typography>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <Typography sx={{ color: '#3D3F43', fontSize: '18px' }}>:</Typography>
              <Typography sx={{ color: '#3D3F43', fontSize: '18px' }}>₹{total || '-'}</Typography>
            </div>
          </div>
        </div>
        <Divider sx={{ mt: 2, mb: 2 }} />
        {/* Description Details */}
        <div style={{ marginLeft: 15 }}>
          <Typography sx={{ fontSize: '22px' }}>Description</Typography>
          <Typography sx={{ mt: 4, fontSize: '18px' }}>{description || '-'}</Typography>
        </div>
        <Divider sx={{ mt: 2, mb: 2 }} />
        {/* Attachments Details */}
        {attachmentsDetails && attachmentsDetails.length > 0 && (
          <div>
            <Typography sx={{ fontSize: '22px', marginLeft: '15px' }}>Attachments</Typography>
            {attachmentsDetails && attachmentsDetails.length > 0 && (
              <div>
                {attachmentsDetails?.map((attachmentsDetail, index) =>
                  attachmentsDetail?.file_type === 'application/pdf' ? (
                    <a
                      key={index}
                      href={attachmentsDetail?.file_url}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <div style={styles.discriptonSvgWrapper}>
                        <Group sx={{ fontSize: '20px' }} />
                        <Typography sx={{ fontSize: '16px' }}>
                          {attachmentsDetail.file_name || '-'}
                        </Typography>
                        <Typography sx={{ fontSize: '12px', color: '#5E6D7C' }}>
                          ({attachmentsDetail.file_size || '-'})KB
                        </Typography>
                      </div>
                    </a>
                  ) : (
                    <div
                      key={index}
                      style={styles.discriptonSvgWrapper}
                      onClick={() => onImageClick(index)}
                    >
                      <Group sx={{ fontSize: '20px' }} />
                      <Typography sx={{ fontSize: '16px' }}>
                        {attachmentsDetail.file_name || '-'}
                      </Typography>
                      <Typography sx={{ fontSize: '12px', color: '#5E6D7C' }}>
                        ({attachmentsDetail.file_size || '-'})KB
                      </Typography>
                    </div>
                  ),
                )}
                {isOpen && (
                  <Lightbox
                    mainSrc={attachmentsDetails[photoIndex].file_url}
                    onCloseRequest={onClickHandlerClose}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Box>
    </Paper>
  );
};

const CreditNotesPreview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { creditNotesDetails, loading, creditNotesLogs } = useAppSelector(s => s.materials);
  const { loading: projectLoading, modulesData, isProjectAdmin } = useAppSelector(s => s.project);

  const [open, setOpen] = useState(false);
  const [modulePermission, setModulePermission] = useState(PERMISSIONS_BASE_STRUCTURE);
  const [openDialog, setOpenDialog] = useState(false);

  const project_id = Cookies.get('_hjproject_idUser_2492391');
  // const project_id = '17';

  const { id } = location.state || {};

  const noteDetail: any = creditNotesDetails?.credit_notes;
  const { supplier_id, credit_remaining } = noteDetail || {};
  const billData = creditNotesDetails?.credit_notes_bills || [];

  const loadData = async () => {
    await dispatch(
      getCreditNotesDetails({
        project_id,
        credit_notes_id: id,
      }),
    );
  };

  useEffect(() => {
    loadData();
    dispatch(
      getCreditNotsLogs({
        project_id,
        credit_notes_id: id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setModulePermission(getPermissions('Credit Notes'));
  }, [modulesData]);

  const handleBack = () => {
    navigate(-1);
  };

  const navToApplyCredits = () => {
    navigate(`/ApplyCredits`, {
      state: {
        project_id,
        supplier_id,
        credit_notes_id: id,
        credit_remaining,
      },
    });
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const navToCreateCreditNotes = () => {
    navigate(`/createCreditNotes`, {
      state: {
        credit_notes_id: id,
      },
    });
  };
  const handleDeleteClick = () => {
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    await removeCreditNotes();
    setOpenDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
  };
  const removeCreditNotes = async () => {
    await dispatch(
      deleteCreditNots({
        project_id,
        credit_notes_id: id,
      }),
    );
    navigate(-1);
  };

  return (
    <>
      <Loader loading={loading && projectLoading} />
      <div
        style={{
          background: '#f6f7fb',
          zIndex: 50,
          height: '84px',
          position: 'sticky',
          top: 0,
          paddingTop: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={styles.mainHeading}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton style={{ color: '#041D36' }} onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
              <HeadingFont style={{ marginLeft: '5px' }}>Supplier Credit Preview</HeadingFont>
            </div>
          </div>
          <div style={styles.topBtn}>
            {billData?.length === 0 && modulePermission?.admin ? (
              <DeleteBtn
                disabled={openDialog}
                disableElevation={true}
                variant="contained"
                onClick={handleDeleteClick}
              >
                Delete
              </DeleteBtn>
            ) : undefined}
            <Dialog
              aria-describedby="alert-dialog-description"
              aria-labelledby="alert-dialog-title"
              open={openDialog}
              sx={{
                position: 'fixed',
                bottom: 0,
                '& .MuiPaper-root ,&.MuiDialog-paper': {
                  width: 500,
                },
              }}
              onClose={handleCancelDelete}
            >
              <DialogTitle id="alert-dialog-title">Are you sure you want to delete?</DialogTitle>
              <DialogActions>
                <ApplyButton onClick={handleCancelDelete}>Cancel</ApplyButton>
                <DeleteBtn autoFocus onClick={handleConfirmDelete}>
                  Delete
                </DeleteBtn>
              </DialogActions>
            </Dialog>

            {modulePermission?.editor || modulePermission?.approval || modulePermission?.admin ? (
              <EditBtn disableElevation={true} variant="contained" onClick={navToCreateCreditNotes}>
                Edit
              </EditBtn>
            ) : undefined}
            {modulePermission?.editor ||
            modulePermission?.approval ||
            modulePermission?.admin ||
            isProjectAdmin ? (
              <ApplyButton disableElevation={true} variant="contained" onClick={navToApplyCredits}>
                Apply To Bills
              </ApplyButton>
            ) : undefined}
            <HistoryButton disableElevation={true} variant="contained" onClick={toggleDrawer}>
              History
            </HistoryButton>
            <div>
              <Drawer
                anchor="right"
                open={open}
                sx={{
                  '& .MuiDrawer-paper': {
                    borderRadius: '12px',
                  },
                }}
                onClose={toggleDrawer}
              >
                <div
                  style={{
                    width: '748px',
                    height: '48px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '24px 32px 24px 32px',
                    marginBottom: '10px',
                  }}
                >
                  <DrawerFont>History</DrawerFont>
                  <CancelIcon
                    sx={{
                      backgroundColor: '#FFE1E1',
                      borderRadius: '80px',
                      height: '26.67px',
                      width: '26.67px',
                      color: '#FF5D5D',
                      cursor: 'pointer',
                    }}
                    onClick={toggleDrawer}
                  />
                </div>
                <Divider sx={{ mt: 2 }} />
                <div style={{ width: '748px' }}>
                  <div style={{ padding: '0 32px', gap: '16px', marginTop: '40px' }}></div>
                </div>
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      paddingLeft: '0px',
                    },
                  }}
                >
                  {creditNotesLogs?.map((e, index) => (
                    <TimelineItem key={index}>
                      <TimelineSeparator
                        sx={{
                          height: '120px',
                          marginBottom: '10px',
                          marginTop: '8px',
                        }}
                      >
                        <StepperActiveIcon />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineCustomContent>
                        <Box style={{ height: '85px' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              marginTop: '0px',
                            }}
                          >
                            {e.user_name}
                            <Typography
                              sx={{
                                color: '#475069',
                                fontSize: '18px',
                                marginLeft: '10px',
                                marginTop: '3px',
                                fontWeight: '300',
                              }}
                            >
                              ({e.datetime})
                            </Typography>
                          </div>
                          <Box
                            style={{
                              height: '44px',
                              background: '#F6F7F9',
                              marginTop: '15px',
                              borderLeft: '2px solid #4872F4',
                              borderRadius: '2px',
                              padding: '5px 16px 5px 16px',
                            }}
                          >
                            <Typography
                              sx={{ fontsize: '18px', color: '#0F132A', marginTop: '3px' }}
                            >
                              {e.log_details}
                            </Typography>
                          </Box>
                        </Box>
                      </TimelineCustomContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Drawer>
            </div>
          </div>
        </div>
        <Divider sx={{ mt: 2 }} />
      </div>
      <Box sx={{ paddingRight: '20px', marginTop: '10px' }}>
        {/* section 1 Bill credit Accordian */}
        <BillCreditedAccordion
          billCreditData={creditNotesDetails}
          credit_notes_id={id}
          isProjectAdmin={isProjectAdmin}
          loadData={loadData}
          modulePermission={modulePermission}
          project_id={project_id}
        />
        {/* Supplier Details */}
        <SupplierDetails supplierDetailsData={creditNotesDetails} />
      </Box>
    </>
  );
};

const styles = {
  mainHeading: {
    marginLeft: '20px',
  },
  topBtn: {
    marginRight: '20px',
  },
  wrapper: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },

  discriptonSvgWrapper: {
    display: 'flex',
    gap: '8px',
    marginBottom: '10px',
    border: '1px',
    borderStyle: 'solid',
    width: 'fit-content',
    padding: '5px',
    borderRadius: '4px',
    borderColor: '#e8e8e8',
    alignItems: 'center',
  },
};

export default CreditNotesPreview;
