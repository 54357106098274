import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FormikProps } from 'formik';
import React, { useMemo } from 'react';
import { useAppSelector } from 'redux/store';
const Label = styled(Typography)`
  color: #3d3f43;
  font-weight: 400;
  font-size: 15;
`;

const Value = styled(Typography)`
  font-size: 15;
  font-weight: 500;
  color: #041d36;
`;
interface UnitInfoProps {
  formikProps: FormikProps<any>; // Replace `any` with the type of your form values if needed
  unit_id: string | number;
}

const UnitInfo = React.forwardRef<HTMLDivElement, UnitInfoProps>((props, ref) => {
  const { formikProps, unit_id } = props;

  const { values } = formikProps;

  const { unitInfo, unitAreaInfo } = useAppSelector(s => s.sales);

  // unitInfo
  const unitInfoValues = useMemo(() => {
    return unitInfo?.booking_unit_sheet_towers_data?.find(
      e => e.project_main_units_id === Number(unit_id),
    );
  }, [unitInfo?.booking_unit_sheet_towers_data, unit_id]);

  return (
    <div ref={ref} className="units-booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <h5>Unit details</h5>
        <div className="form-row">
          <div className="form-group col-sm-6 col-md-4 col-lg-6 col-xl-4">
            <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
              <Label>Unit Information </Label>
              <Value>
                : <strong>{unitInfoValues?.title}</strong>
              </Value>
            </div>
          </div>
          <div className="form-group col-sm-6 col-md-4 col-lg-6 col-xl-4">
            <div style={{ display: 'flex', gap: '5rem', alignItems: 'center' }}>
              <Label>Super Buildup Area </Label>
              <Value>
                : <strong>{unitAreaInfo?.super_build_up_area}</strong>
              </Value>
            </div>
          </div>
          <div className="form-group col-sm-6 col-md-4 col-lg-6 col-xl-4">
            <div className="unit-terrace" style={{ display: 'flex', alignItems: 'center' }}>
              <Label>Terrace Area </Label>
              <Value>
                : <strong>{unitAreaInfo?.terracearea}</strong>
              </Value>
            </div>
          </div>
          <div className="form-group col-sm-6 col-md-4 col-lg-6 col-xl-4">
            <div className="unit-parking" style={{ display: 'flex', alignItems: 'center' }}>
              <Label>Car Parking No </Label>
              <Value>
                : <strong>{values.parking_no}</strong>
              </Value>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default UnitInfo;
