/* eslint-disable react-hooks/exhaustive-deps */
import './Sales.css';

import { ArrowBack as ArrowBackIcon, FilterList } from '@mui/icons-material';
import {
  Badge,
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron_up.svg';
import { ReactComponent as Completed } from 'assets/icons/Completed.svg';
import { ReactComponent as EllipseBlue } from 'assets/icons/ellipse_blue.svg';
import { ReactComponent as EllipseRed } from 'assets/icons/ellipse_red.svg';
import { ReactComponent as EllipseWarning } from 'assets/icons/ellipse_warning.svg';
import Loader from 'components/atoms/Loader';
import NoResult from 'components/atoms/NoResult';
import { PrimaryButton } from 'components/atoms/PrimaryButton';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  getEmployeeWiseSales,
  getFollowUpCount,
  getFollowUpList,
  getLeadFlow,
  getLeadsByPriority,
  getLeadsBySource,
  getPropertySoldByCategory,
  getSalesInquiryFlow,
  getSalesPipeline,
  getSoldUnitsByMonths,
  getSoldUnitsCountBySource,
  getTotalProperties,
  getTowers,
  getVisitorYearOption,
} from 'redux/sales';
import {
  setLeadsFilter,
  setSoldBySourceFilter,
  setSoldUnitsFilter,
} from 'redux/SalesDashboard/actions';
import { useAppDispatch, useAppSelector } from 'redux/store';

import FromToFilter from './components/FromToFilter';
import SalesDashboardFilter from './components/SalesDashboardFilter';

const CommonContainer = styled(Paper)`
  margin-top: 14px;
  padding: 24px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  box-shadow: none;
  gap: 20px;
  border: 1px solid #e3e6e8;
`;

const time = 'today';

const start_month = dayjs().format('YYYY-MM-DD');
const end_month = dayjs().add(3, 'month').format('YYYY-MM-DD');
const Year = dayjs().format('YYYY');
type CustomCardProps = {
  title: string;
  value: number;
  percentage?: number;
};

const CustomCard = ({ title, value, percentage }: CustomCardProps) => (
  <Card
    sx={{
      flex: 1,
      background: '#F5F7FF',
      boxShadow: 'none',
      '& .MuiCardContent-root:last-child': { paddingBottom: '18px' },
    }}
  >
    <CardContent
      sx={{
        p: '18px 20px 0px 20px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3>{value}</h3>
        {percentage >= 0 && <h5>{percentage} %</h5>}
      </div>
      {}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 1,
        }}
      >
        <h5>{title}</h5>
        {/* <ChevronRight /> */}
      </Box>
    </CardContent>
  </Card>
);
const Sales = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showDescription, setShowDescription] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedYear, setSelectedYear] = useState(Year);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState({
    totalInquiry: false,
    followup: false,
    inquiryFlow: false,
    salesPipe: false,
    leadPriority: false,
    sourceType: false,
    totalProperty: false,
    tower: false,
    soldMonth: false,
    propertyCategory: false,
    propertySources: false,
    empInquiry: false,
  });
  // url params
  // const project_id = searchParams.get('project_id');
  const project_id = Cookies.get('_hjproject_idUser_2492391');
  // const project_id = '17';
  // const user_id = Cookies.get('_hjuser_idUser_2492391');
  // const user_id = '2';
  const {
    getSalesLeadData,
    getFollowUpCountData,
    followUpData,
    salesPipelineData,
    leadsByPriorityData,
    leadsBySourceData,
    getTotalPropertiesData,
    getTowersData,
    getSoldUnitsByMonthsData,
    getSoldUnitsCountBySourceData,
    loading,
    getPropertySoldByCategoryData,
    getSalesInquiryFlowData,
    getVisitorYearOptionData,
    getEmployeeWiseSalesData,
    userFilter,
    userInfoOption,
  } = useAppSelector(s => s.sales);
  const { filter: leadsFilter } = useAppSelector(s => s.leadFilter);
  const { filter: sourceFilter } = useAppSelector(s => s.soldBySourceFilter);
  const { filter: unitsFilter } = useAppSelector(s => s.soldUnitsFilter);

  const { total_leads, daily, weekly, monthly } = getSalesLeadData;

  const { today, overdue, tomorrow } = getFollowUpCountData;

  const pipelineTotal = salesPipelineData?.pipelines?.map(item => item?.total);

  const pipelineTitle = salesPipelineData?.pipelines?.map(item => item?.title);

  const priorityOrder = { high: 1, medium: 2, low: 3 };

  const priorityPercent = leadsByPriorityData?.leads
    ?.slice()
    ?.sort((a, b) => priorityOrder[a.priority] - priorityOrder[b.priority])
    ?.map(item => item.percent);

  const priority = leadsByPriorityData?.leads;
  const priorityHigh = priority?.find(v => v.priority === 'high');
  const priorityMedium = priority?.find(v => v.priority === 'medium');
  const priorityLow = priority?.find(v => v.priority === 'low');
  const PriorityColors = [];
  if (priorityHigh) PriorityColors.push('#FF5D5D');
  if (priorityMedium) PriorityColors.push('#F4AF48');
  if (priorityLow) PriorityColors.push('#4872F4');

  const PriorityLabels = [];
  if (priorityHigh) PriorityLabels.push('High');
  if (priorityMedium) PriorityLabels.push('Medium');
  if (priorityLow) PriorityLabels.push('Low');

  const { total_leads: priortyTotalLeads } = leadsByPriorityData;
  const { total_leads: sourceTypeTotalLeads } = leadsBySourceData;

  const sourceFilterData =
    leadsBySourceData?.leads?.filter(
      item => item?.id !== null && item?.title !== null && item?.title !== '',
    ) || [];
  const sourceCount = sourceFilterData?.map(v => v?.count);
  const sourceTitle = sourceFilterData?.map(v => v?.title);
  const {
    total_properties,
    available,
    booked,
    hold,
    reserved,
    booked_percentage,
    available_percentage,
    hold_percentage,
    reserved_percentage,
  } = getTotalPropertiesData;
  const towerBooked = getTowersData?.map(item => item?.booked_count ?? 0);
  const towerAvailable = getTowersData?.map(item => item?.available_count ?? 0);
  const towerReserved = getTowersData?.map(item => item?.reserved_count ?? 0);

  const towerLabel = getTowersData?.map(item => item?.label);
  const { from, to, ...monthsData } = getSoldUnitsByMonthsData;
  const monthEntries = Object?.entries(monthsData)?.map(([month, value]) => ({
    month,
    value,
  }));

  const unitsSourceFilter = getSoldUnitsCountBySourceData?.filter(
    item => item?.source_title !== null && item?.source_title !== '',
  );

  const unitsSourceTotal = unitsSourceFilter?.map(item => item?.total);

  const unitsSourceTitle = getSoldUnitsCountBySourceData?.map(item => item?.source_title);

  const PropertySoldByCategoryList = getPropertySoldByCategoryData?.property_sold_by_category;
  const SalesInquirySeries = getSalesInquiryFlowData?.map(v => v.count);
  const SalesInquiryMonthName = getSalesInquiryFlowData?.map(v => v.month);
  const EmployeeName = getEmployeeWiseSalesData?.map(v => v.first_name);
  const EmployeeAssigned = getEmployeeWiseSalesData?.map(v => v.assigned);
  const EmployeeConverted = getEmployeeWiseSalesData?.map(v => v.converted);
  const Followupcompleted = followUpData?.filter(v => v.completed === 'yes').length;
  const FollowupIncompleted = followUpData?.filter(v => v.completed === 'no').length;

  const loadData = () => {
    dispatch(getLeadFlow({ project_id }));
    dispatch(getFollowUpCount({ project_id, user_id: userFilter.user_id }));
    dispatch(getSalesPipeline({ project_id, user_id: userFilter.user_id }));
    dispatch(getLeadsByPriority({ project_id, user_id: userFilter.user_id }));
    dispatch(getTotalProperties({ project_id }));
    dispatch(getTowers({ project_id }));
    dispatch(getSoldUnitsByMonths({ project_id, start_month, end_month, filter: 'current' }));
    dispatch(
      getSoldUnitsCountBySource({ project_id, filter: 'current', user_id: userFilter.user_id }),
    );
    dispatch(getFollowUpList({ project_id, time, user_id: userFilter.user_id }));
    dispatch(getPropertySoldByCategory({ project_id }));
    dispatch(getSalesInquiryFlow({ project_id, user_id: userFilter.user_id, year: selectedYear }));
    dispatch(getVisitorYearOption({ project_id }));
    dispatch(getEmployeeWiseSales({ project_id, filter: '3' }));
    setSelectedTime(time);
  };
  // api calls
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilter]);

  const handleClick = time => {
    setSelectedTime(time);
    dispatch(getFollowUpList({ project_id, time, user_id: userFilter.user_id }));
  };
  useEffect(() => {
    handleSourceTypeByMonth(leadsFilter);
    handleSoldBySourceMonth(sourceFilter);
    handleSoldUnitsByMonth(unitsFilter);
  }, []);
  const handleSourceTypeByMonth = filter => {
    dispatch(setLeadsFilter(filter));
    dispatch(getLeadsBySource({ project_id, filter, user_id: userFilter.user_id }));
  };
  const handleSoldBySourceMonth = filter => {
    dispatch(setSoldBySourceFilter(filter));
    dispatch(getSoldUnitsCountBySource({ project_id, filter, user_id: userFilter.user_id }));
  };
  const handleSoldUnitsByMonth = filter => {
    dispatch(setSoldUnitsFilter(filter));
    dispatch(getSoldUnitsByMonths({ project_id, start_month, end_month, filter }));
  };

  const yearOption = useMemo(() => {
    const listOptions = getVisitorYearOptionData?.map(year => ({
      value: Number(year),
      label: year.toString(),
    }));
    listOptions.sort((a, b) => b.value - a.value);
    return [...listOptions];
  }, [getVisitorYearOptionData]);

  const handleYearChange = selectedOption => {
    setSelectedYear(selectedOption.value); // Update the selected year in state
    dispatch(
      getSalesInquiryFlow({ project_id, user_id: userFilter.user_id, year: selectedOption.value }),
    );
  };
  const toggleDescription = index => {
    setShowDescription(previndex => (previndex === index ? null : index));
  };
  const topRef = useRef(null);

  const scrollToTop = () => {
    topRef.current.scrollIntoView({ top: 0, left: 0, behavior: 'smooth' });
  };

  const toggleDrawer = () => {
    setToggleOpen(!toggleOpen);
  };
  const handleFilterDrawer = section => {
    console.log(section, 'section');

    setFilterOpen(prevState => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };
  console.log(filterOpen, 'f');

  const titleWidth = sourceTitle?.length > 10;
  const sourceTitleWidth = titleWidth ? sourceTitle?.length * 300 : '100%';

  const SalesOptions: ApexOptions = {
    chart: {
      toolbar: { show: false },
    },
    states: {
      hover: { filter: { type: 'none' } },
      active: { filter: { type: 'none' } },
    },
    tooltip: { enabled: true },
    plotOptions: {
      bar: {
        columnWidth: 38,
        borderRadius: 2.64,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      style: {
        colors: ['#041D36'],
        fontSize: '16px',
        fontFamily: 'nunitosemibold',
        fontWeight: 600,
      },
    },
    xaxis: {
      categories: pipelineTitle || [],
      title: {
        text: 'Inquiry status',
        style: {
          color: '#95A0AC',
          fontSize: '14px',
          fontFamily: 'nunitoregular',
          fontWeight: 400,
        },
      },
      labels: {
        style: {
          colors: ['#041D36'],
          fontSize: '16px',
          fontFamily: 'nunito',
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      title: {
        text: 'Number of Inquiry',
        style: {
          color: '#95A0AC',
          fontSize: '14px',
          fontFamily: 'nunitoregular',
          fontWeight: 400,
        },
      },
    },
    fill: {
      colors: ['#2EF3F3'],
    },
  };

  const PieChart = {
    series: priorityPercent || [],
    options: {
      legend: { show: false, markers: { fillColors: ['#F4AF48', '#07CA03', '#FF5D5D'] } },
      dataLabels: { enabled: false },
      labels: PriorityLabels,
      colors: PriorityColors,
      tooltip: { enabled: true },
      fill: { colors: PriorityColors },
      states: {
        hover: { filter: { type: 'none' } },
        active: { filter: { type: 'none' } },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
        },
      },
    },
  };
  const SourceTypePieChart = {
    series: sourceCount.length > 0 ? sourceCount : [0],
    options: {
      legend: {
        show: true,
        markers: { fillColors: [] },
        itemMargin: {
          vertical: 10,
        },
      },
      dataLabels: { enabled: false },
      labels: sourceTitle,
      tooltip: { enabled: true },
      states: {
        hover: { filter: { type: 'none' } },
        active: { filter: { type: 'none' } },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
        },
      },
    },
  };
  const sourceTypeCount = sourceCount?.every(count => count === 0);

  const SourceOptions: ApexOptions = {
    chart: {
      toolbar: { show: false },
    },
    states: {
      hover: { filter: { type: 'none' } },
      active: { filter: { type: 'none' } },
    },
    tooltip: { enabled: true },
    plotOptions: {
      bar: {
        columnWidth: 28,
        borderRadius: 2.64,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      colors: ['#A848F4'],
    },
    xaxis: {
      categories: sourceTitle,
      title: {
        text: 'All sources',
        style: {
          color: '#95A0AC',
          fontSize: '14px',
          fontFamily: 'nunitoregular',
          fontWeight: 400,
        },
      },
      labels: {
        style: {
          colors: ['#041D36'],
          fontSize: '14px',
          fontFamily: 'nunitoregular',
          fontWeight: 400,
        },
        rotate: 360, // Rotate labels by -45 degrees
        // offsetY: 0,
        // offsetX: 0,
        // trim: true,
        // formatter: function (value) {
        //   // You can customize label formatting here if needed
        //   return value;
        // },
      },
    },
    yaxis: {
      title: {
        text: 'Lead by Count',
        style: {
          color: '#95A0AC',
          fontSize: '14px',
          fontFamily: 'nunitoregular',
          fontWeight: 400,
        },
      },
    },
  };

  const TowerSeries = [
    {
      name: 'Reserved',
      data: towerReserved,
    },
    {
      name: 'Available',
      data: towerAvailable,
    },
    {
      name: 'Booking',
      data: towerBooked,
    },
  ];

  const TowerOptions: ApexOptions = {
    chart: {
      toolbar: { show: false },
    },
    legend: { show: false },
    states: {
      hover: { filter: { type: 'none' } },
      active: { filter: { type: 'none' } },
    },
    tooltip: { enabled: true },
    plotOptions: {
      bar: {
        columnWidth: 45,
        borderRadius: 2.64,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      style: {
        colors: ['#041D36'],
        fontSize: '10px',
        fontWeight: 600,
      },
    },
    stroke: {
      show: true,
      width: 5,
      colors: ['transparent'],
    },
    fill: {
      colors: ['#747474', '#10bc41', '#bd0f0f'],
      opacity: 1,
    },
    xaxis: {
      categories: towerLabel,
      title: {
        text: 'Towers',
        style: {
          color: '#95A0AC',
          fontSize: '14px',
          fontWeight: 400,
        },
      },
      labels: {
        style: {
          colors: ['#041D36'],
          fontSize: '16px',
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      title: {
        text: 'Lead by Count',
        style: {
          color: '#95A0AC',
          fontSize: '14px',
          fontFamily: 'nunitoregular',
          fontWeight: 400,
        },
      },
    },
  };

  const UnitsSeries = [
    {
      name: 'Units',
      data: monthEntries?.map(item => item?.value),
    },
  ];

  const UnitsOptions: ApexOptions = {
    chart: {
      toolbar: { show: false },
    },
    states: {
      hover: { filter: { type: 'none' } },
      active: { filter: { type: 'none' } },
    },
    tooltip: { enabled: true },
    plotOptions: {
      bar: {
        columnWidth: 35,
        borderRadius: 2.64,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      style: {
        colors: ['#041D36'],
        fontSize: '16px',
        fontFamily: 'nunitosemibold',
        fontWeight: 600,
      },
    },
    fill: {
      colors: ['#7AB9F4'],
    },
    yaxis: {
      title: {
        text: 'Number of Units',
        style: {
          color: '#95A0AC',
          fontSize: '14px',
          fontFamily: 'nunitoregular',
          fontWeight: 400,
        },
      },
    },
    xaxis: {
      categories: monthEntries?.map(item => item?.month),
      labels: {
        style: {
          colors: ['#041D36'],
          fontSize: '16px',
          fontFamily: 'nunito',
          fontWeight: 400,
        },
      },
    },
  };

  const PropertySoldSeries = [
    {
      name: 'Source',
      data: unitsSourceTotal,
    },
  ];

  const PropertySoldOptions: ApexOptions = {
    chart: {
      toolbar: { show: false },
    },
    states: {
      hover: { filter: { type: 'none' } },
      active: { filter: { type: 'none' } },
    },
    tooltip: { enabled: true },
    plotOptions: {
      bar: {
        columnWidth: 35,
        borderRadius: 2.64,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      style: {
        fontSize: '16px',
        fontFamily: 'nunitosemibold',
        fontWeight: 600,
      },
    },
    fill: {
      colors: ['#4A6B4D'],
      opacity: 1,
    },
    yaxis: {
      title: {
        text: 'Property count',
        style: {
          color: '#95A0AC',
          fontSize: '14px',
          fontFamily: 'nunitoregular',
          fontWeight: 400,
        },
      },
    },
    xaxis: {
      categories: unitsSourceTitle || [],
      labels: {
        style: {
          colors: ['#041D36'],
          fontSize: '16px',
          fontFamily: 'nunito',
          fontWeight: 400,
        },
      },
      title: {
        text: 'All sources',
        style: {
          color: '#95A0AC',
          fontSize: '14px',
          fontFamily: 'nunitoregular',
          fontWeight: 400,
        },
      },
    },
  };
  const InquiryChartSeries = [
    {
      name: 'Inquiry',
      type: 'area',
      data: SalesInquirySeries,
    },
  ];
  const InquiryChartOptions: ApexOptions = {
    chart: {
      type: 'line',
      toolbar: { show: false },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      colors: ['#0085FF'],
    },
    fill: {
      type: 'solid',
      colors: ['#CEE7FF'],
      opacity: [0.5],
    },
    markers: {
      size: 0,
    },
    yaxis: [
      {
        title: {
          text: 'Number of inquiry',
          style: {
            color: '#95A0AC',
            fontSize: '14px',
            fontFamily: 'nunitoregular',
            fontWeight: 400,
          },
        },
      },
    ],
    xaxis: {
      categories: SalesInquiryMonthName || [],
      labels: {
        style: {
          colors: ['#041D36'],
          fontSize: '16px',
          fontFamily: 'nunito',
          fontWeight: 400,
        },
      },
      title: {
        text: 'Month',
        style: {
          color: '#95A0AC',
          fontSize: '14px',
          fontFamily: 'nunitoregular',
          fontWeight: 400,
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== 'undefined') {
            return y.toFixed(0);
          }
          return y;
        },
      },
    },
  };
  const EmployeeInquirySeries = [
    {
      name: 'Assigned inquiry',
      data: EmployeeAssigned,
    },
    {
      name: 'Converted inquiry',
      data: EmployeeConverted,
    },
  ];
  const EmployeeInqiryOption: ApexOptions = {
    chart: {
      toolbar: { show: false },
    },
    legend: { show: false },
    states: {
      hover: { filter: { type: 'none' } },
      active: { filter: { type: 'none' } },
    },
    tooltip: { enabled: true },
    plotOptions: {
      bar: {
        columnWidth: 45,
        borderRadius: 2.64,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      style: {
        fontSize: '10px',
        fontWeight: 600,
        // colors: [

        // ],
      },
    },
    stroke: {
      show: true,
      width: 5,
      colors: ['transparent'],
    },
    fill: {
      colors: ['#058503', '#041D36'],
      opacity: 1,
    },
    xaxis: {
      categories: EmployeeName,
      labels: {
        style: {
          colors: ['#041D36'],
          fontSize: '16px',
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      title: {
        text: 'Inquires',
        style: {
          color: '#95A0AC',
          fontSize: '14px',
          fontFamily: 'nunitoregular',
          fontWeight: 400,
        },
      },
    },
  };
  const totalBar = TowerSeries?.reduce((total, series) => total + series?.data?.length || 0, 0);
  const barWidth = 100;

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Loader loading={loading} />
      <SalesDashboardFilter open={toggleOpen} toggleDrawer={toggleDrawer} />

      <Box
        sx={{
          p: '14px 24px',
          background: '#fff',
          mt: 1,
          height: `calc(100vh - 73px)`,
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Box
          ref={topRef}
          className="sales-title-container d-flex align-items-center justify-content-between"
        >
          <div className="d-flex align-items-center my-3">
            <IconButton
              className="mr-3 mb-2"
              style={{ backgroundColor: '#e5eafa', color: '#4872f4' }}
              onClick={handleBack}
            >
              <ArrowBackIcon />
            </IconButton>
            <h3 className="mb-0">
              Sales Dashboard <Chip className="mb-2" color="success" label="Beta" />
            </h3>
          </div>
          {userInfoOption?.length > 0 ? (
            <div>
              <IconButton
                aria-label="filter"
                color="primary"
                sx={{ backgroundColor: 'rgb(229 234 250)' }}
                onClick={toggleDrawer}
              >
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  // badgeContent={filterCount}
                  color="primary"
                >
                  <FilterList />
                </Badge>
              </IconButton>
            </div>
          ) : undefined}

          {/* <Box className="d-flex align-item-center ">
            <IconButton disableTouchRipple style={{ backgroundColor: '#EDF1FE' }}>
              <Calendar />
            </IconButton>
            <PrimaryButton
              disableTouchRipple
              endIcon={<ChevronDown />}
              sx={{ paddingLeft: '20px' }}
            >
              <span style={{ fontSize: 24, fontWeight: 600, lineHeight: '32.74px' }}>
                Sales Dashboard
              </span>
            </PrimaryButton>
          </Box> */}
        </Box>

        <CommonContainer>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Inquiry flow</h4>
            {/* <IconButton
              aria-label="filter"
              color="primary"
              sx={{ backgroundColor: 'rgb(229 234 250)' }}
              onClick={() => handleFilterDrawer('totalInquiry')}
            >
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                // badgeContent={filterCount}
                color="primary"
              >
                <FilterList />
              </Badge>
            </IconButton> */}
          </div>
          <div className="d-flex align-items-center align-items-center">
            <h6>Total Inquiry</h6>
            <Chip
              label={total_leads > 0 ? total_leads : 0}
              sx={{ background: '#5E6D7C', color: '#fff', ml: 1 }}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
            <CustomCard title="Daily" value={daily} />
            <CustomCard title="Weekly" value={weekly} />
            <CustomCard title="Monthly" value={monthly} />
          </div>
          {filterOpen.totalInquiry && (
            <FromToFilter
              filterDrawer={() => handleFilterDrawer('totalInquiry')}
              open={filterOpen.totalInquiry}
            />
          )}
        </CommonContainer>

        <CommonContainer>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ display: 'flex', gap: 8 }}>
                <h4 className="mr-3">Follow up</h4>
                <Chip
                  label={`Today's (${today || 0})`}
                  sx={{
                    color: selectedTime === 'today' ? '#fff' : '#3D3F43',
                    background: selectedTime === 'today' ? '#4872F4' : '#fff',
                    borderRadius: 1,
                    p: '8px 18px',
                    transition: 'none',
                    border: selectedTime === 'today' ? 'none' : '1px solid #C3C3C3',
                    '&:hover': {
                      backgroundColor: selectedTime === 'today' ? '#4872F4' : '#fff',
                    },
                    '&:active': {
                      boxShadow: 'none',
                    },
                  }}
                  onClick={() => handleClick('today')}
                />
                <Chip
                  label={`Tomorrow's (${tomorrow || 0})`}
                  sx={{
                    color: selectedTime === 'tomorrow' ? '#fff' : '#3D3F43',
                    background: selectedTime === 'tomorrow' ? '#4872F4' : '#fff',
                    borderRadius: 1,
                    p: '8px 18px',
                    transition: 'none',
                    border: selectedTime === 'tomorrow' ? 'none' : '1px solid #C3C3C3',
                    '&:hover': {
                      backgroundColor: selectedTime === 'tomorrow' ? '#4872F4' : '#fff',
                    },
                    '&:active': {
                      boxShadow: 'none',
                    },
                  }}
                  onClick={() => handleClick('tomorrow')}
                />
                <Chip
                  label={`Overdue (${overdue || 0})`}
                  sx={{
                    color: selectedTime === 'overdue' ? '#fff' : '#3D3F43',
                    background: selectedTime === 'overdue' ? '#4872F4' : '#fff',
                    borderRadius: 1,
                    p: '8px 18px',
                    transition: 'none',
                    border: selectedTime === 'overdue' ? 'none' : '1px solid #C3C3C3',
                    '&:hover': {
                      backgroundColor: selectedTime === 'overdue' ? '#4872F4' : '#fff',
                    },
                    '&:active': {
                      boxShadow: 'none',
                    },
                  }}
                  onClick={() => handleClick('overdue')}
                />
              </div>
              {/* <IconButton
                aria-label="filter"
                color="primary"
                sx={{ backgroundColor: 'rgb(229 234 250)' }}
                onClick={() => handleFilterDrawer('followup')}
              >
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  // badgeContent={filterCount}
                  color="primary"
                >
                  <FilterList />
                </Badge>
              </IconButton> */}
            </div>
            {selectedTime === 'today' && (
              <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <Typography color="#868686">Completed</Typography>
                <Typography>
                  : <strong>{Followupcompleted}</strong>
                </Typography>
                <Typography color="#868686">Incomplete</Typography>
                <Typography>
                  : <strong>{FollowupIncompleted}</strong>
                </Typography>
              </div>
            )}

            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Follow up task </th>
                    <th>
                      {selectedTime === 'today' ? 'Follow up with / phone' : 'Follow up with'}{' '}
                    </th>
                    <th>
                      {selectedTime === 'today' ? 'Created by / date & time' : 'Phone number'}
                    </th>
                    <th> {selectedTime === 'today' ? 'Completion remark' : 'Created by'}</th>
                    <th>{selectedTime === 'today' ? 'Completion date & time' : 'Date & time'}</th>
                  </tr>
                </thead>
                {followUpData?.length > 0 ? (
                  <tbody>
                    {followUpData?.map((rowData, rowIndex) => (
                      <tr key={rowIndex}>
                        <td>
                          {selectedTime === 'today' ? (
                            <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                              {rowData.completed === 'yes' && <Completed />}
                              <span dangerouslySetInnerHTML={{ __html: rowData.task_title }} />
                            </div>
                          ) : (
                            <span dangerouslySetInnerHTML={{ __html: rowData.task_title }} />
                          )}
                        </td>
                        <td>
                          {selectedTime === 'today' ? (
                            <div
                              style={{
                                display: 'flex',
                                gap: 5,
                                flexDirection: 'column',
                              }}
                            >
                              <div style={{ color: '#4872F4' }}>{rowData.visitor_name}</div>
                              <div> {rowData.phone}</div>
                            </div>
                          ) : (
                            <div style={{ color: '#4872F4' }}>{rowData.visitor_name}</div>
                          )}
                        </td>
                        <td>
                          {selectedTime === 'today' ? (
                            <div
                              style={{
                                display: 'flex',
                                gap: 5,
                                flexDirection: 'column',
                              }}
                            >
                              <div> {rowData.created_by}</div>
                              <div>{dayjs(rowData.created).format('DD-MMM-YYYY hh:mm:ss')}</div>
                            </div>
                          ) : (
                            <div> {rowData.phone}</div>
                          )}
                        </td>
                        <td>
                          {selectedTime === 'today' ? (
                            <>
                              {rowData.completed_remarks === null ? (
                                ''
                              ) : (
                                <div className="d-flex align-items-baseline mt-2">
                                  {showDescription === rowIndex ||
                                  rowData.completed_remarks.split(' ').length <= 2 ? (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: rowData.completed_remarks,
                                      }}
                                    />
                                  ) : (
                                    <>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: rowData.completed_remarks
                                            .split(' ')
                                            .slice(0, 2)
                                            .join(' '),
                                        }}
                                      />
                                      <PrimaryButton
                                        className="p-0 justify-content-start ml-2"
                                        onClick={() => toggleDescription(rowIndex)}
                                      >
                                        ...More
                                      </PrimaryButton>
                                    </>
                                  )}
                                </div>
                              )}
                            </>
                          ) : (
                            <div> {rowData.created_by}</div>
                          )}
                        </td>
                        <td>
                          {selectedTime === 'today' ? (
                            <div>
                              {rowData.completed_datetime
                                ? dayjs(rowData.completed_datetime).format('DD-MMM-YYYY hh:mm:ss')
                                : '-'}
                            </div>
                          ) : (
                            <div>
                              {dayjs(rowData.followup_date).format('DD-MMM-YYYY')}{' '}
                              {rowData.followup_time}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={5}>
                        <NoResult />
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
          {filterOpen?.followup && (
            <FromToFilter
              filterDrawer={() => handleFilterDrawer('followup')}
              open={filterOpen.followup}
            />
          )}
        </CommonContainer>
        <CommonContainer>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Inquiry flow</h4>
            <div className="d-flex">
              <Select
                closeMenuOnSelect={true}
                name="year"
                options={yearOption}
                placeholder={selectedYear}
                styles={{
                  container: (base: any) => ({
                    ...base,
                    marginRight: 10,
                  }),
                }}
                onChange={handleYearChange}
              />
              {/* <IconButton
                aria-label="filter"
                color="primary"
                sx={{ backgroundColor: 'rgb(229 234 250)' }}
                onClick={() => handleFilterDrawer('inquiryFlow')}
              >
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  // badgeContent={filterCount}
                  color="primary"
                >
                  <FilterList />
                </Badge>
              </IconButton> */}
            </div>
          </div>

          <ReactApexChart
            height={311}
            options={InquiryChartOptions}
            series={InquiryChartSeries}
            type="line"
          />
          {filterOpen.inquiryFlow && (
            <FromToFilter
              filterDrawer={() => handleFilterDrawer('inquiryFlow')}
              open={filterOpen.inquiryFlow}
            />
          )}
        </CommonContainer>
        <CommonContainer>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Sales pipeline</h4>
            {/* <IconButton
              aria-label="filter"
              color="primary"
              sx={{ backgroundColor: 'rgb(229 234 250)' }}
              onClick={() => handleFilterDrawer('salesPipe')}
            >
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                // badgeContent={filterCount}
                color="primary"
              >
                <FilterList />
              </Badge>
            </IconButton> */}
          </div>
          <ReactApexChart
            height={311}
            options={SalesOptions}
            series={[{ name: 'Inquiry', data: pipelineTotal }]}
            type="bar"
          />
          {filterOpen.salesPipe && (
            <FromToFilter
              filterDrawer={() => handleFilterDrawer('salesPipe')}
              open={filterOpen.salesPipe}
            />
          )}
        </CommonContainer>
        <div className="sales-grid-container">
          <CommonContainer>
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                <h4>Lead by priority</h4>
                <div className="d-flex align-items-center">
                  <h6>Total Inquiry</h6>
                  <Chip
                    label={priortyTotalLeads > 0 ? priortyTotalLeads : 0}
                    sx={{ background: '#5E6D7C', color: '#fff', ml: 1 }}
                  />
                </div>
              </div>
              {/* <IconButton
                aria-label="filter"
                color="primary"
                sx={{ backgroundColor: 'rgb(229 234 250)' }}
                onClick={() => handleFilterDrawer('leadPriority')}
              >
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  // badgeContent={filterCount}
                  color="primary"
                >
                  <FilterList />
                </Badge>
              </IconButton> */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <ReactApexChart
                height={250}
                options={PieChart.options}
                series={PieChart.series}
                type="donut"
              />
              <div
                style={{ display: 'flex', gap: 28, alignItems: 'center', justifyContent: 'center' }}
              >
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  <EllipseRed />
                  <h6>{`${
                    priority?.find(item => item.priority === 'high')?.percent || 0
                  }% High`}</h6>
                </div>
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  <EllipseWarning />
                  <h6>{`${
                    priority?.find(item => item.priority === 'medium')?.percent || 0
                  }% Medium`}</h6>
                </div>
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  <EllipseBlue />
                  <h6>{`${priority?.find(item => item.priority === 'low')?.percent || 0}% Low`}</h6>
                </div>
              </div>
            </div>
            {filterOpen.leadPriority && (
              <FromToFilter
                filterDrawer={() => handleFilterDrawer('leadPriority')}
                open={filterOpen.leadPriority}
              />
            )}
          </CommonContainer>
          <CommonContainer>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <h4>Source Type</h4>
              <div className="d-flex align-items-center">
                <h6>Total Inquiry</h6>
                <Chip
                  label={sourceTypeTotalLeads > 0 ? sourceTypeTotalLeads : 0}
                  sx={{ background: '#5E6D7C', color: '#fff', ml: 1 }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              {sourceTypeCount ? (
                <div className="mt-5">
                  <NoResult />
                </div>
              ) : (
                <ReactApexChart
                  height={250}
                  options={SourceTypePieChart.options}
                  series={SourceTypePieChart.series}
                  type="donut"
                />
              )}
            </div>
          </CommonContainer>
        </div>

        <CommonContainer style={{ flex: '0 0 auto', minWidth: '300px' }}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="justify-content-start">
              <h4>Source type</h4>
            </div>
            <div className="justify-content-end">
              <button
                className={leadsFilter === '6' ? 'sales-btn selected' : 'sales-btn '}
                style={{ marginRight: 5 }}
                onClick={() => handleSourceTypeByMonth('6')}
              >
                6 mth
              </button>
              <button
                className={leadsFilter === '3' ? 'sales-btn selected' : 'sales-btn '}
                style={{ marginRight: 5 }}
                onClick={() => handleSourceTypeByMonth('3')}
              >
                3 mth
              </button>
              <button
                className={leadsFilter === 'current' ? 'sales-btn selected' : 'sales-btn'}
                style={{ marginRight: 5 }}
                onClick={() => handleSourceTypeByMonth('current')}
              >
                Current mth
              </button>
              {/* <IconButton
                aria-label="filter"
                color="primary"
                sx={{ backgroundColor: 'rgb(229 234 250)' }}
                onClick={() => handleFilterDrawer('sourceType')}
              >
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  // badgeContent={filterCount}
                  color="primary"
                >
                  <FilterList />
                </Badge>
              </IconButton> */}
            </div>
          </div>

          <div style={{ overflowX: 'auto', width: '100%', minWidth: '100%', margin: '0 auto' }}>
            <ReactApexChart
              height={450}
              options={SourceOptions}
              series={[{ name: 'Source', data: sourceCount }]}
              type="bar"
              width={sourceTitleWidth}
            />
          </div>
          {filterOpen.sourceType && (
            <FromToFilter
              filterDrawer={() => handleFilterDrawer('sourceType')}
              open={filterOpen.sourceType}
            />
          )}
        </CommonContainer>

        <Box sx={{ padding: 2, background: '#EFEFEF', borderRadius: 1, mt: '14px' }}>
          <h4 style={{ color: '#4872F4' }}>Property</h4>
        </Box>

        <CommonContainer>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Properties</h4>
            {/* <IconButton
              aria-label="filter"
              color="primary"
              sx={{ backgroundColor: 'rgb(229 234 250)' }}
              onClick={() => handleFilterDrawer('totalProperty')}
            >
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                // badgeContent={filterCount}
                color="primary"
              >
                <FilterList />
              </Badge>
            </IconButton> */}
          </div>
          <div className="d-flex align-items-center">
            <h6>Total Properties</h6>
            <Chip label={total_properties} sx={{ background: '#5E6D7C', color: '#fff', ml: 1 }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
            <CustomCard percentage={available_percentage} title="Available" value={available} />
            <CustomCard percentage={reserved_percentage} title="Reserved" value={reserved} />
            <CustomCard percentage={booked_percentage} title="Booked" value={booked} />
            <CustomCard percentage={hold_percentage} title="Hold" value={hold} />
          </div>
          {filterOpen.totalProperty && (
            <FromToFilter
              filterDrawer={() => handleFilterDrawer('totalProperty')}
              open={filterOpen.totalProperty}
            />
          )}
        </CommonContainer>

        <CommonContainer>
          <div className="sales-title-container">
            <div className="d-flex">
              <h4>Towers</h4>
              <div style={{ display: 'flex', gap: 24, alignItems: 'center', marginLeft: 15 }}>
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  <Box sx={{ width: 14, height: 14, background: '#747474' }}></Box>
                  <h6>Reserved</h6>
                </div>
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  <Box sx={{ width: 14, height: 14, background: '#10bc41' }}></Box>
                  <h6>Available</h6>
                </div>
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  <Box sx={{ width: 14, height: 14, background: '#bd0f0f' }}></Box>
                  <h6>Booked</h6>
                </div>
              </div>
            </div>
            {/* <IconButton
              aria-label="filter"
              color="primary"
              sx={{ backgroundColor: 'rgb(229 234 250)' }}
              onClick={() => handleFilterDrawer('tower')}
            >
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                // badgeContent={filterCount}
                color="primary"
              >
                <FilterList />
              </Badge>
            </IconButton> */}
          </div>
          <div style={{ overflowX: 'auto', width: '100%', minWidth: '100%', margin: '0 auto' }}>
            <ReactApexChart
              height={346}
              options={TowerOptions}
              series={TowerSeries}
              type="bar"
              width={totalBar > 30 ? `${totalBar * barWidth}px` : '100%'}
            />
          </div>
          {filterOpen.tower && (
            <FromToFilter
              filterDrawer={() => handleFilterDrawer('tower')}
              open={filterOpen.tower}
            />
          )}
        </CommonContainer>

        <CommonContainer>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="justify-content-start">
                <h4>Sold by month</h4>
              </div>
              <div className="justify-content-end">
                <button
                  className={unitsFilter === '6' ? 'sales-btn selected' : 'sales-btn'}
                  style={{ marginRight: 5 }}
                  onClick={() => handleSoldUnitsByMonth('6')}
                >
                  6 mth
                </button>
                <button
                  className={unitsFilter === '3' ? 'sales-btn selected' : 'sales-btn'}
                  style={{ marginRight: 5 }}
                  onClick={() => handleSoldUnitsByMonth('3')}
                >
                  3 mth
                </button>
                <button
                  className={unitsFilter === 'current' ? 'sales-btn selected' : 'sales-btn'}
                  style={{ marginRight: 5 }}
                  onClick={() => handleSoldUnitsByMonth('current')}
                >
                  Current mth
                </button>
                {/* <IconButton
                  aria-label="filter"
                  color="primary"
                  sx={{ backgroundColor: 'rgb(229 234 250)' }}
                  onClick={() => handleFilterDrawer('soldMonth')}
                >
                  <Badge
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    // badgeContent={filterCount}
                    color="primary"
                  >
                    <FilterList />
                  </Badge>
                </IconButton> */}
              </div>
            </div>

            <h6 style={{ color: '#5E6D7C' }}>
              From {from} To {to}
            </h6>
          </div>
          <ReactApexChart height={316} options={UnitsOptions} series={UnitsSeries} type="bar" />
          {filterOpen.soldMonth && (
            <FromToFilter
              filterDrawer={() => handleFilterDrawer('soldMonth')}
              open={filterOpen.soldMonth}
            />
          )}
        </CommonContainer>
        <CommonContainer>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Property sold by category</h4>
            {/* <IconButton
              aria-label="filter"
              color="primary"
              sx={{ backgroundColor: 'rgb(229 234 250)' }}
              onClick={() => handleFilterDrawer('propertyCategory')}
            >
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                // badgeContent={filterCount}
                color="primary"
              >
                <FilterList />
              </Badge>
            </IconButton> */}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <table className="table category-table">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Total</th>
                  <th>Booked</th>
                  <th>Reserved</th>
                  <th>Hold</th>
                  <th>Available</th>
                </tr>
              </thead>
              <tbody>
                {PropertySoldByCategoryList?.map((rowData, rowIndex) => (
                  <tr key={rowIndex}>
                    <td>{rowData.category}</td>
                    <td style={{ color: '#4872f4' }}>{rowData.totalCount}</td>
                    <td style={{ color: '#ff5d5d' }}>{rowData.totalBookedCount}</td>
                    <td>{rowData.totalreservedCount}</td>
                    <td style={{ color: '#F4AF48' }}>{rowData.totalholdCount}</td>
                    <td style={{ color: '#07ca03' }}>{rowData.available}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {filterOpen.propertyCategory && (
            <FromToFilter
              filterDrawer={() => handleFilterDrawer('propertyCategory')}
              open={filterOpen.propertyCategory}
            />
          )}
        </CommonContainer>
        <CommonContainer>
          <div className="d-flex justify-content-between align-items-center">
            <div className="justify-content-start">
              <h4>Property sold by sources</h4>
            </div>
            <div className="justify-content-end">
              <button
                className={sourceFilter === '6' ? 'sales-btn selected' : 'sales-btn'}
                style={{ marginRight: 5 }}
                onClick={() => handleSoldBySourceMonth('6')}
              >
                6 mth
              </button>
              <button
                className={sourceFilter === '3' ? 'sales-btn selected' : 'sales-btn'}
                style={{ marginRight: 5 }}
                onClick={() => handleSoldBySourceMonth('3')}
              >
                3 mth
              </button>
              <button
                className={sourceFilter === 'current' ? 'sales-btn selected' : 'sales-btn'}
                style={{ marginRight: 5 }}
                onClick={() => handleSoldBySourceMonth('current')}
              >
                Current mth
              </button>
              {/* <IconButton
                aria-label="filter"
                color="primary"
                sx={{ backgroundColor: 'rgb(229 234 250)' }}
                onClick={() => handleFilterDrawer('propertySources')}
              >
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  // badgeContent={filterCount}
                  color="primary"
                >
                  <FilterList />
                </Badge>
              </IconButton> */}
            </div>
          </div>

          <ReactApexChart
            height={346}
            options={PropertySoldOptions}
            series={PropertySoldSeries}
            type="bar"
          />
          {filterOpen.propertySources && (
            <FromToFilter
              filterDrawer={() => handleFilterDrawer('propertySources')}
              open={filterOpen.propertySources}
            />
          )}
        </CommonContainer>
        <CommonContainer>
          <div className="d-flex justify-content-between">
            <h4>Employee wise inquiry</h4>
            <div style={{ display: 'flex', gap: 24, alignItems: 'center', marginLeft: 15 }}>
              <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                <Box sx={{ width: 14, height: 14, background: '#041D36' }}></Box>
                <h6>Converted inquiry</h6>
              </div>
              <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                <Box sx={{ width: 14, height: 14, background: '#058503' }}></Box>
                <h6>Assigned inquiry</h6>
              </div>
              {/* <IconButton
                aria-label="filter"
                color="primary"
                sx={{ backgroundColor: 'rgb(229 234 250)' }}
                onClick={() => handleFilterDrawer('empInquiry')}
              >
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  // badgeContent={filterCount}
                  color="primary"
                >
                  <FilterList />
                </Badge>
              </IconButton> */}
            </div>

            {/* <div className="justify-content-end">
              <button
                className={sourceFilter === '6' ? 'sales-btn selected' : 'sales-btn'}
                style={{ marginRight: 5 }}
                onClick={() => handleSoldBySourceMonth('6')}
              >
                6 mth
              </button>
              <button
                className={sourceFilter === '3' ? 'sales-btn selected' : 'sales-btn'}
                style={{ marginRight: 5 }}
                onClick={() => handleSoldBySourceMonth('3')}
              >
                3 mth
              </button>
              <button
                className={sourceFilter === 'current' ? 'sales-btn selected' : 'sales-btn'}
                onClick={() => handleSoldBySourceMonth('current')}
              >
                Current mth
              </button>
            </div> */}
          </div>

          <ReactApexChart
            height={346}
            options={EmployeeInqiryOption}
            series={EmployeeInquirySeries}
            type="bar"
          />
          {filterOpen.empInquiry && (
            <FromToFilter
              filterDrawer={() => handleFilterDrawer('empInquiry')}
              open={filterOpen.empInquiry}
            />
          )}
        </CommonContainer>

        <IconButton
          disableRipple
          style={{
            position: 'fixed',
            bottom: '40px',
            right: '60px',
            height: 52,
            width: 52,
            borderRadius: 8,
            background: '#4872F4',
            boxShadow: '0px 4px 8px 3px #00000026',
          }}
          onClick={scrollToTop}
        >
          <ChevronUp />
        </IconButton>
      </Box>
    </>
  );
};
export default Sales;
