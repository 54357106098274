import CloseIcon from '@mui/icons-material/Delete';
import {
  IconButton,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { getCreditBillsList } from 'redux/materials';
import { useAppDispatch, useAppSelector } from 'redux/store';
import theme from 'Theme';

const StyleTableCell = styled(TableCell)(() => {
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'unset',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '4px',
      position: 'relative',
      '&:not(&:nth-child(1) )': {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 20,
          left: 0,
          bottom: 20,
          width: '1px',
          background: `${theme.palette.grey[300]}`,
        },
      },
    },
    [`&.${tableCellClasses.body}`]: {
      border: '0px 0px 2px 0px',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      position: 'relative',
    },
  };
});

const TableRowFonts = styled(Typography)`
  && {
    font-size: 18px;
    weight: 500;
  }
`;

const BillRow = ({ index, bill, handleBillUpdate, handleBillRemove }) => {
  const dispatch = useAppDispatch();
  const { invoice_date, bill_amount, bill_balance, credit_amount, invoice_id } = bill || {};

  const { invoiceList } = useAppSelector(s => s.materials);

  const creditBilllistOptions = useMemo(() => {
    const creditBilllistOptions = invoiceList?.map(e => ({
      label: `${e.invoice_no}`,
      value: e.id,
      detail: e,
    }));
    return [...creditBilllistOptions];
  }, [invoiceList]);

  useEffect(() => {
    dispatch(
      getCreditBillsList({
        project_id: '17',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (invoice_id) {
      const invoiceData = creditBilllistOptions?.find(bill => bill.value === Number(invoice_id));

      const { invoice_date, bill_amount, bill_balance }: any = invoiceData.detail || {};
      handleBillUpdate(index, 'invoice_date', invoice_date);
      handleBillUpdate(index, 'bill_amount', bill_amount);
      handleBillUpdate(index, 'bill_balance', bill_balance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice_id]);

  // function useDisableScrollOnNumberInput() {
  useEffect(() => {
    const disableScroll = () => {
      const el = document?.activeElement;

      if (!(el instanceof HTMLInputElement) || el?.type !== 'number') return;

      el.blur();
      setTimeout(() => el.focus({ preventScroll: true }), 0);
    };

    document.addEventListener('wheel', disableScroll);
    return () => document.removeEventListener('wheel', disableScroll);
  }, []);
  // }

  return (
    <TableRow key={index}>
      <StyleTableCell className="pl-0">
        <Select
          menuPosition={'fixed'}
          name={`bills.${index}.invoice_id`}
          options={creditBilllistOptions}
          placeholder="Select Number"
          styles={{
            container: (base: any) => ({
              ...base,
              width: '100%',
              paddingRight: '0px',
              marginRight: '5px',
            }),
          }}
          onChange={e => handleBillUpdate(index, 'invoice_id', e.value)}
        />
      </StyleTableCell>
      <StyleTableCell>
        <TableRowFonts>{invoice_date || '-'}</TableRowFonts>
      </StyleTableCell>
      <StyleTableCell>
        <TableRowFonts>{`₹ ${bill_amount || 0}`}</TableRowFonts>
      </StyleTableCell>
      <StyleTableCell>
        <TableRowFonts>{`₹ ${bill_balance || 0}`}</TableRowFonts>
      </StyleTableCell>
      <StyleTableCell sx={{ fontWeight: '500', fontSize: '18px', width: '10%' }}>
        <TextField
          name={`bills.${index}.credit_amount`}
          placeholder="0.00"
          size="small"
          sx={{
            '& .MuiInputBase-root': {
              '& input': {
                textAlign: 'right',
              },
            },
            '& .MuiTableCell-root': {
              padding: '0px',
            },
            //   width: '100%',
          }}
          type="number"
          value={credit_amount}
          variant="outlined"
          // onKeyUp={handleInputNumberEvents}
          // onKeyDown={handleInputNumberEvents}
          onChange={e =>
            handleBillUpdate(index, 'credit_amount', (e.target as HTMLInputElement).valueAsNumber)
          }
          // onWheel={handleInputNumberEvents}
        />
      </StyleTableCell>
      <StyleTableCell>
        <IconButton aria-label="delete" color="error" onClick={() => handleBillRemove(index)}>
          <CloseIcon />
        </IconButton>
      </StyleTableCell>
    </TableRow>
  );
};

export default BillRow;
