import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useSyncedFields } from 'hooks/useDiscountCalculator';
import { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { useAppSelector } from 'redux/store';

const CalculationMethod = props => {
  const { formikProps, baseAmount, unitAreaInfo } = props;
  const { values, handleBlur, setFieldValue, setValues } = formikProps;

  const { CLPInstallmentList: installmentsList } = useAppSelector(s => s.sales);

  useEffect(() => {
    setValues({
      ...values,
      basic_rate:
        values.calculation_method === 'rate_base'
          ? unitAreaInfo?.rate_base_amt
          : unitAreaInfo?.fixed_amount || 0,
      basic_rate_disc_amt: 0,
      basic_rate_disc_per: 0,
      basic_rate_basic_amount: 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.calculation_method]);

  // discount calculations hook
  const discountSyncedFields = useSyncedFields(
    baseAmount,
    'basic_rate_disc_amt',
    'basic_rate_disc_per',
    setFieldValue,
  );

  // const resetGovTaxes = () => {
  //   setFieldValue('gst_amt', 0);
  //   setFieldValue('stampduty_amount', 0);
  //   setFieldValue('reg_amount', 0);
  //   setFieldValue('gst_per', 0);
  //   setFieldValue('stampduty_per', 0);
  //   setFieldValue('reg_per', 0);
  // };

  // installment Options
  const rateOptions = useMemo(() => {
    return installmentsList?.map(e => ({
      label: e.rate || 0,
      value: e.id,
    }));
  }, [installmentsList]);

  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <h5>Rate Calculation</h5>
        <div className="form-row ml-3">
          <div className="form-group col-md-8 form-col-gap">
            <div className="row w-100 align-items-center">
              <p className="mb-0 mr-4">
                <b>Calculation Method : </b>
              </p>
              <RadioGroup row aria-labelledby="installments-selection" name="installment-selection">
                <FormControlLabel
                  checked={values?.calculation_method === 'rate_base'}
                  className="mb-0 mr-5"
                  control={
                    <Radio
                      onClick={() => {
                        setFieldValue('calculation_method', 'rate_base');
                      }}
                    />
                  }
                  label="Rate Based"
                  value="rate_base"
                />
                <FormControlLabel
                  checked={values?.calculation_method === 'fixed_amount'}
                  className="mb-0  mr-5"
                  control={
                    <Radio
                      onClick={() => {
                        setFieldValue('calculation_method', 'fixed_amount');
                      }}
                    />
                  }
                  label="Fixed Amount"
                  value="fixed_amount"
                />
                <FormControlLabel
                  checked={values?.calculation_method === 'clp_base'}
                  className="mb-0"
                  control={
                    <Radio
                      onClick={() => {
                        setFieldValue('calculation_method', 'clp_base');
                      }}
                    />
                  }
                  label="CLP Based"
                  value="clp_base"
                />
              </RadioGroup>
            </div>
          </div>
        </div>
        {values.calculation_method === 'rate_base' ? (
          <div>
            {/* Rate Based */}
            <table className="table">
              <thead>
                <th>Sr No</th>
                <th>Description</th>

                <th>Area</th>
                <th className="text-right">Rate</th>
                <th className="text-right">Discount</th>
                <th className="text-right">Basic Amount</th>
              </thead>
              <tbody>
                <tr>
                  <td>01</td>
                  <td>Basic rate of unit</td>
                  <td>
                    <input
                      readOnly
                      className="form-control"
                      name="basic_rate_area"
                      type="number"
                      value={values?.basic_rate_area}
                      onBlur={handleBlur}
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      name="basic_rate"
                      style={{ textAlign: 'right' }}
                      type="number"
                      value={values.basic_rate}
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('basic_rate', e.target.value);
                        setFieldValue('basic_rate_disc_amt', 0);
                        setFieldValue('basic_rate_disc_per', 0);
                        // resetGovTaxes();
                      }}
                    />
                  </td>
                  <td>
                    <div style={{ display: 'flex', gap: 10 }}>
                      <input
                        className="form-control"
                        name="basic_rate_disc_per"
                        placeholder="%"
                        style={{ width: '20%' }}
                        type="number"
                        value={values.basic_rate_disc_per}
                        onBlur={handleBlur}
                        onChange={e => {
                          discountSyncedFields.onChangePercent(e);
                          // resetGovTaxes();
                        }}
                      />
                      <input
                        className="form-control"
                        name="basic_rate_disc_amt"
                        placeholder="Amount"
                        style={{ textAlign: 'right' }}
                        type="number"
                        value={values.basic_rate_disc_amt}
                        onBlur={handleBlur}
                        onChange={e => {
                          discountSyncedFields.onChangeAmount(e);
                          // resetGovTaxes(); 
                        }}
                      />
                    </div>
                  </td>
                  <td className="text-right">
                    <label>{Number(values.basic_rate_basic_amount).toFixed(2)}</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : values.calculation_method === 'fixed_amount' ? (
          <div>
            {/* Fixed Amount Based */}
            <table className="table">
              <thead>
                <th>Sr No</th>
                <th>Description</th>
                <th className="text-right">Rate</th>
                <th className="text-right">Discount</th>
                <th className="text-right">Basic Amount</th>
              </thead>
              <tbody>
                <tr>
                  <td>01</td>
                  <td>Basic rate of unit</td>
                  <td>
                    <input
                      className="form-control"
                      name="basic_rate"
                      placeholder="Amount"
                      style={{ textAlign: 'right' }}
                      type="number"
                      value={values.basic_rate < 0 ? 0 : values.basic_rate}
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('basic_rate', e.target.value);
                        setFieldValue('basic_rate_disc_amt', 0);
                        setFieldValue('basic_rate_disc_per', 0);
                        // resetGovTaxes();
                      }}
                    />
                  </td>
                  <td>
                    <div style={{ display: 'flex', gap: 10 }}>
                      <input
                        className="form-control"
                        name="basic_rate_disc_per"
                        placeholder="%"
                        style={{ width: '20%' }}
                        type="number"
                        value={values.basic_rate_disc_per}
                        onBlur={handleBlur}
                        onChange={e => {
                          discountSyncedFields.onChangePercent(e);
                          // resetGovTaxes();
                        }}
                      />
                      <input
                        className="form-control"
                        name="basic_rate_disc_amt"
                        placeholder="Amount"
                        style={{ textAlign: 'right' }}
                        type="number"
                        value={values.basic_rate_disc_amt}
                        onBlur={handleBlur}
                        onChange={e => {
                          discountSyncedFields.onChangeAmount(e);
                          // resetGovTaxes();
                        }}
                      />
                    </div>
                  </td>
                  <td className="text-right">
                    <label>
                      {values.basic_rate_basic_amount < 0 ? 0 : values.basic_rate_basic_amount}
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : values.calculation_method === 'clp_base' ? (
          <div>
            {/* Rate Based */}
            <table className="table">
              <thead>
                <th>Sr No</th>
                <th>Description</th>

                <th>Area</th>
                <th className="text-right">Rate</th>
                <th className="text-right">Discount</th>
                <th className="text-right">Basic Amount</th>
              </thead>
              <tbody>
                <tr>
                  <td>01</td>
                  <td>Basic rate of unit</td>
                  <td>
                    <input
                      readOnly
                      className="form-control"
                      name="basic_rate_area"
                      type="number"
                      value={values?.basic_rate_area}
                      onBlur={handleBlur}
                    />
                  </td>
                  <td>
                    <Select
                      closeMenuOnSelect={true}
                      name="basic_rate"
                      options={rateOptions}
                      placeholder="Rates List"
                      styles={{
                        container: base => ({
                          ...base,
                        }),
                      }}
                      value={rateOptions?.find(e => e.value === values.clp_bank_id) || null}
                      onChange={e => {
                        // resetGovTaxes();
                        setFieldValue('basic_rate', e.label);
                        setFieldValue('clp_bank_id', e.value);
                        setFieldValue('basic_rate_disc_amt', 0);
                        setFieldValue('basic_rate_disc_per', 0);
                      }}
                    />
                  </td>
                  <td>
                    <div style={{ display: 'flex', gap: 10 }}>
                      <input
                        className="form-control"
                        name="basic_rate_disc_per"
                        placeholder="%"
                        style={{ width: '20%' }}
                        type="number"
                        value={values.basic_rate_disc_per}
                        onBlur={handleBlur}
                        onChange={e => {
                          discountSyncedFields.onChangePercent(e);
                          // resetGovTaxes();
                        }}
                      />
                      <input
                        className="form-control"
                        name="basic_rate_disc_amt"
                        placeholder="Amount"
                        style={{ textAlign: 'right' }}
                        type="number"
                        value={values.basic_rate_disc_amt}
                        onBlur={handleBlur}
                        onChange={e => {
                          discountSyncedFields.onChangeAmount(e);
                          // resetGovTaxes();
                        }}
                      />
                    </div>
                  </td>
                  <td className="text-right">
                    <label>{Number(values.basic_rate_basic_amount).toFixed(2)}</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : undefined}
      </div>
    </div>
  );
};

export default CalculationMethod;
