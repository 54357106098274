import { combineReducers } from '@reduxjs/toolkit';

import constructiondashboard from './ConstructionDashboard';
import customersection from './customerSection';
import materials from './materials';
import project from './project';
import sales from './sales';
import {
  leadsFilterReducer,
  soldBySourceFilterReducer,
  soldUnitsFilterReducer,
} from './SalesDashboard/reducer';
const reducers = combineReducers({
  sales,
  materials,
  project,
  customersection,
  constructiondashboard,
  leadFilter: leadsFilterReducer,
  soldUnitsFilter: soldUnitsFilterReducer,
  soldBySourceFilter: soldBySourceFilterReducer,
});

export default reducers;
