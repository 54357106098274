import { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { getTermsnConditions } from 'redux/sales';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { HTML_REGEX } from 'utils/constant';

const Terms = props => {
  const { formikProps, project_id } = props;
  const { values, handleChange, setFieldValue } = formikProps;

  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  // const [isOTPSubmission, setOTPSubmission] = useState(false);

  // redux state values
  const { termsList } = useAppSelector(s => s.sales);

  // const SAVE_WITH_OTP_SWITCH = booking_validation?.form2_otp_on_off === 'yes' ? true : false;

  // t&c
  const termsOptions = useMemo(() => {
    return termsList?.map(e => ({
      label: e.title,
      value: e.id,
      details: e.description,
    }));
  }, [termsList]);

  useEffect(() => {
    dispatch(getTermsnConditions({ project_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <h5>TERMS & CONDITIONS</h5>

        <div className="form-row mb-4">
          <div className="col-4">
            <label>Select T&C Template</label>
            <Select
              closeMenuOnSelect={true}
              options={termsOptions}
              placeholder="Select Terms & Conditions"
              styles={{
                container: (base: any) => ({
                  ...base,
                  marginTop: 10,
                  marginBottom: 50,
                }),
              }}
              value={termsOptions.find(e => e.value === values.custom_payment_remark_id)}
              onChange={e => {
                setFieldValue('custom_payment_remark_id', e.value);
                setFieldValue('custom_payment_remark', e.details);
                setFieldValue('custom_payment_remark', e.details);
              }}
            />
          </div>
          <div className="col-10 px-0">
            <textarea
              className="form-control"
              cols={100}
              name="custom_payment_remark"
              rows={10}
              style={{ height: 'unset' }}
              value={values?.custom_payment_remark?.replace(HTML_REGEX, '')}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
