import Select from 'react-select';

const Parking = props => {
  const { PARKING_SWITCH, parkingInfo, ParkingOption, formikProps } = props;
  const { values, handleBlur, setFieldValue } = formikProps;

  const selectedParkingOptions = values?.parking_no?.split(',')?.map(id => {
    const option = ParkingOption.find(option => option.value === Number(id));
    return option;
  });

  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <h5>Allocate Parking</h5>
        {PARKING_SWITCH ? (
          <div className="parking-table">
            <table className="table w-50">
              <thead>
                <th className="w-25">Sr No</th>
                <th>Parking No.</th>
              </thead>
              {parkingInfo?.map((item, index) => (
                <tbody key={index}>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.parking_no}</td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        ) : (
          <div className="form-row">
            <div className="col-12">
              <label style={{ fontSize: 16, fontWeight: 500 }}>Select No of Parking</label>
              <Select
                isMulti
                options={ParkingOption}
                styles={{
                  container: (base: any) => ({
                    ...base,
                    width: '31%',
                  }),
                }}
                value={selectedParkingOptions}
                onBlur={handleBlur}
                onChange={v => {
                  if (Array.isArray(v)) {
                    setFieldValue('parking_no', v.map(i => i?.value).join(','));
                  }
                }}
                closeMenuOnSelect={true}
                // defaultValue={EDIT ? selectedParkingOptions : null}
                noOptionsMessage={() => 'No Data Found'}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Parking;
