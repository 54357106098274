import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppBar, Box, IconButton, styled, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React, { useMemo } from 'react';
const PageHeader = styled(AppBar)`
  background: #f6f7fb;
  box-shadow: none;
  color: #041d36;
  align-items: center;
  padding: 10px 20px 10px 40px;
  justify-content: space-between;
  border-bottom: 0.5px solid #d7d3d373;
`;

interface BookingHeaderProps {
  formikProps: FormikProps<any>; // Replace `any` with the type of your form values if needed
  handleDownloadPDF: () => void; // Function type for handleDownloadPDF
  navigate: (path: string | number) => void; // Type for the navigate function (e.g., for react-router)
}

const BookingHeader = React.forwardRef<HTMLDivElement, BookingHeaderProps>((props, ref) => {
  const { navigate, formikProps } = props;

  const { errors } = formikProps;

  // handling validations
  const validationErrors = useMemo(() => {
    const parsed = [];

    Object?.values(errors)?.map(error => {
      if (Array.isArray(error)) {
        error.map(err => {
          if (err) {
            Object?.values(err)?.map(v => {
              if (v && !parsed.includes(v)) {
                parsed.push(v);
              }
            });
          }
        });
      } else {
        if (error && !parsed.includes(error)) {
          parsed.push(error);
        }
      }
    });
    return parsed;
  }, [errors]);
  const chipStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '12px',
    paddingRight: '12px',
    height: '32px',
    backgroundColor: '#2e7d32',
    borderRadius: '25px',
    fontSize: '16px',
    color: '#fff',
    marginLeft: '5px',
  };

  return (
    <PageHeader ref={ref} position="sticky">
      <Box className="d-flex align-item-center justify-content-between w-100">
        <Box className="d-flex align-item-center">
          <IconButton
            style={{ backgroundColor: '#e5eafa', color: '#4872f4' }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box className="d-flex align-item-center px-2">
            <Typography variant="h5">Booking Quotation</Typography>
            <div style={chipStyle}>
              <span>Beta</span>
            </div>
            {/* <Chip color="success" label="Beta" style={{ marginLeft: 10 }} /> */}
          </Box>
        </Box>
        {/* <button className="btn btn-primary " onClick={handleDownloadPDF}>
          Download PDF
        </button> */}
      </Box>

      {/* error box */}
      <Box className="w-100 mt-2 mx-4">
        {validationErrors.length ? (
          <Box
            sx={{
              background: 'rgba(255, 93, 93, 0.1)',
              borderRadius: 2,
              padding: `5px`,
              fontSize: '1rem',
            }}
          >
            <ul>
              {validationErrors?.map((err, index) => {
                return (
                  <li key={index}>
                    <Typography color={'#ff5d5d'} variant="h6">
                      {JSON.parse(JSON.stringify(err))}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </Box>
        ) : undefined}
      </Box>
    </PageHeader>
  );
});

export default BookingHeader;
