import { cloneDeep } from 'lodash';
import { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { getCLPInstallmentData } from 'redux/sales';
import { useAppDispatch, useAppSelector } from 'redux/store';

import CustomLinkInstallmentRow from './CustomLinkInstallmentRow';

const CustomLinkInstallments = props => {
  const dispatch = useAppDispatch();

  const { formikProps, project_id, extraCharges, OCList } = props;
  const { values, setFieldValue } = formikProps;

  // redux state values
  const {
    CLPInstallmentList: installmentsList,
    CLPInstallmentsInformation: installmentsInformation,
  } = useAppSelector(s => s.sales);

  // useEffect(() => {
  //   dispatch(getCLPInstallmentOptions({ project_id, tower_id }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    handleTotalPaymentCharge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.clp_installments]);

  useEffect(() => {
    mapPaymentInstallments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installmentsInformation]);

  const mapPaymentInstallments = () => {
    const schedules = installmentsInformation?.length
      ? installmentsInformation
      : values.clp_installments;

    const updatedData = schedules?.map(installment => ({
      clp_payment_no: installmentsInformation?.length
        ? installment?.id
        : installment.clp_payment_no,
      clp_payment_installment: installmentsInformation?.length
        ? installment?.name
        : installment.clp_payment_installment,
      clp_per: installmentsInformation?.length
        ? Number(installment?.percentage)
        : Number(installment.clp_per),
      clp_basic_amt: installmentsInformation?.length ? 0 : Number(installment.clp_basic_amt),
      clp_otherchages_amt: installmentsInformation?.length
        ? 0
        : Number(installment.clp_otherchages_amt),
      clp_extrachages_amt: installmentsInformation?.length
        ? 0
        : Number(installment.clp_extrachages_amt),
      clp_installment_amount: installmentsInformation?.length
        ? 0
        : Number(installment.clp_installment_amount),
      clp_gst: installmentsInformation?.length ? 0 : Number(installment.clp_gst),
    }));

    if (
      !updatedData.filter(
        installment => installment.clp_payment_installment === 'Other Charges (Separately)',
      )?.length
    ) {
      updatedData.push({
        clp_payment_no: (values?.installments?.length ?? 0) + 1,
        clp_payment_installment: 'Other Charges (Separately)',
        clp_otherchages_amt: 0,
        clp_extrachages_amt: 0,
        clp_installment_amount: 0,
        clp_gst: 0,
        lastRow: 'true',
      } as any);
    }

    setFieldValue('clp_installments', updatedData);
  };

  const handleTotalPaymentCharge = () => {
    let total = 0;
    values.clp_installments?.forEach(charge => {
      total += parseFloat(charge?.clp_installment_amount) || 0;
    });

    setFieldValue('custom_payment_total_amount', total.toFixed(2));
    return total.toFixed(2);
  };

  const handlePaymentScheduleUpdate = (index, field, value) => {
    const newUnitRates = cloneDeep(values.clp_installments);

    newUnitRates[index] = {
      ...newUnitRates[index],
      [field]: value,
    };

    const basicAmount = Number(newUnitRates[index].clp_basic_amt) || 0;
    const otherChargesAmt = Number(newUnitRates[index].clp_otherchages_amt) || 0;
    const extraChargesAmt = Number(newUnitRates[index].clp_extrachages_amt) || 0;

    // Calculate the totalPaymentSchedule
    const totalPaymentSchedule =
      basicAmount + otherChargesAmt + newUnitRates[index].gst + extraChargesAmt;

    newUnitRates[index].clp_basic_amt = Number(basicAmount.toFixed(2));

    newUnitRates[index].clp_installment_amount = Number(totalPaymentSchedule.toFixed(2));

    setFieldValue('clp_installments', newUnitRates);
  };

  const handleInstallmentChange = e => {
    dispatch(
      getCLPInstallmentData({
        project_id: Number(project_id),
        clp_id: e.value,
      }),
    );
    setFieldValue('clp_bank_id', e.value);
    mapPaymentInstallments();
  };

  // installment calculations
  const calculateInstallments = () => {
    let updatedList = cloneDeep(values.clp_installments);

    updatedList?.map(installment => {
      installment.clp_basic_amt = 0;
      installment.clp_otherchages_amt = 0;
      installment.clp_extrachages_amt = 0;
      installment.clp_installment_amount = 0;
      installment.clp_gst = 0;

      return installment;
    });

    updatedList?.map(installment => {
      if (!installment.lastRow) {
        const calculatedAmount =
          (Number(values.basic_rate_basic_amount) * installment.clp_per) / 100;

        installment.clp_basic_amt = Number(calculatedAmount);
        installment.clp_installment_amount += Number(calculatedAmount);

        return installment;
      }
      return installment;
    });

    extraCharges?.map(extraCharge => {
      const { extra_charges_distribution_method, extra_charges_total } = extraCharge;
      const installmentLen = (updatedList?.length ?? 0) > 1 ? updatedList?.length - 1 : 1;

      switch (extra_charges_distribution_method) {
        case 'Equally with all installments': {
          const equallyDistributedAmount = extra_charges_total / installmentLen;

          updatedList = updatedList?.map((installment, index) => {
            if (index !== installmentLen && !installment?.lastRow) {
              installment.clp_extrachages_amt += Number(equallyDistributedAmount);

              installment.clp_installment_amount += Number(equallyDistributedAmount);

              return installment;
            }
            return installment;
          });

          break;
        }

        case 'Proportionately with all installment': {
          updatedList = updatedList?.map((installment, index) => {
            if (index !== installmentLen && !installment?.lastRow) {
              installment.clp_extrachages_amt += Number(
                (extra_charges_total * installment.clp_per) / 100,
              );

              installment.clp_installment_amount += Number(
                (extra_charges_total * installment.clp_per) / 100,
              );
            }
            return installment;
          });
          break;
        }

        case 'Proportionately with all installment(Except First)': {
          const proportionatelyDistributedAmount = extra_charges_total / (installmentLen - 1);

          updatedList = updatedList?.map((installment, index) => {
            if (index !== 0 && index !== installmentLen && !installment?.lastRow) {
              installment.clp_extrachages_amt += Number(
                (proportionatelyDistributedAmount * installment.clp_per) / 100,
              );

              installment.clp_installment_amount += Number(
                (proportionatelyDistributedAmount * installment.clp_per) / 100,
              );
            }
            return installment;
          });
          break;
        }

        case 'Connect with last installment': {
          updatedList = updatedList?.map((installment, index) => {
            if (index === installmentLen - 1 && !installment?.lastRow) {
              installment.clp_extrachages_amt += Number(extra_charges_total);

              installment.clp_installment_amount += Number(extra_charges_total);
            }
            return installment;
          });
          break;
        }

        case 'Dont connect with installment': {
          updatedList = updatedList?.map(installment => {
            if (
              installment?.lastRow ||
              installment?.custom_payment_installment === 'Other Charges (Separately)'
            ) {
              installment.clp_extrachages_amt += Number(extra_charges_total);

              installment.clp_installment_amount += Number(extra_charges_total);
            }
            return installment;
          });
          break;
        }

        default:
      }
    });

    OCList?.map(oclist => {
      const { other_charges_distribution_method, other_charges_amount } = oclist;
      const installmentLen = updatedList?.length > 1 ? updatedList?.length - 1 : 1;

      switch (other_charges_distribution_method) {
        case 'Equally with all installments': {
          const equallyDistributedAmount = other_charges_amount / installmentLen;

          updatedList = updatedList?.map((installment, index) => {
            if (index !== installmentLen && !installment?.lastRow) {
              installment.clp_otherchages_amt += Number(equallyDistributedAmount);

              installment.clp_installment_amount += Number(equallyDistributedAmount);
              return installment;
            }
            return installment;
          });

          break;
        }

        case 'Proportionately with all installment': {
          updatedList = updatedList?.map((installment, index) => {
            if (index !== installmentLen && !installment?.lastRow) {
              installment.clp_otherchages_amt += Number(
                (other_charges_amount * installment.clp_per) / 100,
              );
              installment.clp_installment_amount += Number(
                (other_charges_amount * installment.clp_per) / 100,
              );
            }
            return installment;
          });

          break;
        }

        case 'Proportionately with all installment(Except First)': {
          const proportionatelyDistributedAmount = other_charges_amount / (installmentLen - 1);

          updatedList = updatedList?.map((installment, index) => {
            if (index !== 0 && index !== installmentLen && !installment?.lastRow) {
              installment.clp_otherchages_amt += Number(
                (proportionatelyDistributedAmount * installment.clp_per) / 100,
              );
              installment.clp_installment_amount += Number(
                (proportionatelyDistributedAmount * installment.clp_per) / 100,
              );
            }
            return installment;
          });

          break;
        }

        case 'Connect with last installment': {
          updatedList = updatedList?.map((installment, index) => {
            if (index === installmentLen - 1 && !installment?.lastRow) {
              installment.clp_otherchages_amt += Number(Number(other_charges_amount).toFixed(2));
              installment.clp_installment_amount += Number(Number(other_charges_amount).toFixed(2));
            }
            return installment;
          });

          break;
        }

        case 'Dont connect with installment': {
          updatedList = updatedList?.map(installment => {
            if (
              installment?.lastRow ||
              installment?.custom_payment_installment === 'Other Charges (Separately)'
            ) {
              installment.clp_otherchages_amt += Number(Number(other_charges_amount).toFixed(2));
              installment.clp_installment_amount += Number(Number(other_charges_amount).toFixed(2));
            }
            return installment;
          });

          break;
        }

        default:
      }
    });

    updatedList?.map(installment => {
      const calculatedGSTAmount =
        ((Number(installment.clp_basic_amt) + Number(installment.clp_otherchages_amt)) *
          values.gst_per) /
        100;

      installment.clp_gst = Number(calculatedGSTAmount.toFixed(2));
      installment.clp_installment_amount += Number(calculatedGSTAmount.toFixed(2));

      return installment;
    });

    setFieldValue('clp_installments', updatedList);
  };

  // installment Options
  const installmentOptions = useMemo(() => {
    let installments = installmentsList?.map(e => ({
      label: e.name,
      value: e.id,
    }));

    if (values.calculation_method === 'clp_base') {
      installments = installments?.filter(
        singleInstallment => singleInstallment.value === values.clp_bank_id,
      );
    }

    return installments;
  }, [installmentsList, values.calculation_method, values.clp_bank_id]);

  return (
    <div className="booking-form-box shwan-form pt-0 mt-4 shadow-none">
      <div className="booking-form-col-12">
        {/* <Typography variant="subtitle2">Construction Link Plan</Typography> */}

        <div className="form-row">
          <div className="col-6 ">
            <label>Select Payment Installment</label>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <Select
                closeMenuOnSelect={true}
                options={installmentOptions}
                placeholder="Select Payment Installment"
                styles={{
                  container: (base: any) => ({
                    ...base,
                    marginTop: 10,
                    marginBottom: 20,
                    width: '25rem',
                  }),
                }}
                onChange={handleInstallmentChange}
              />
              <button
                className="Btn btn-lightblue-primary lbps-btn py-2"
                id="butng"
                type="button"
                onClick={() => {
                  calculateInstallments();
                }}
              >
                Calculate
              </button>
            </div>
          </div>
        </div>
        <div>
          <table className="table">
            <thead>
              <th>Sr No</th>
              <th>Installment Name</th>
              <th>%</th>
              <th>Basic Amount</th>
              <th>Other Charges Amount</th>
              <th>GST</th>
              <th>Extra Charges Amount</th>
              <th className="text-right">Installment Amount</th>
              <th></th>
            </thead>
            <tbody>
              {values.clp_installments?.map((e, i) => (
                <CustomLinkInstallmentRow
                  key={i}
                  e={e}
                  formikProps={formikProps}
                  handlePaymentScheduleUpdate={handlePaymentScheduleUpdate}
                  i={i}
                />
              ))}
              <tr>
                <td
                  className="text-right"
                  colSpan={8}
                  style={{ borderTop: 'none', padding: '5px 0px' }}
                >
                  <div className="total-background">
                    <label style={{ fontSize: '1.1rem' }}>
                      <span style={{ marginRight: 6 }}> Installments Total</span>
                      <span style={{ marginRight: 6 }}>:</span>
                      <span>₹ {values.custom_payment_total_amount}</span>
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomLinkInstallments;
