import { FormikProps } from 'formik';
import React from 'react';

import OtherChargeRow from './OtherChargeRow';
interface OtherChargesProps {
  formikProps: FormikProps<any>;
  handleOCListChange: (index: any, field: any, value: any) => void;
  handleTotalOtherCharge: () => string;
  OCList: any[];
}
const OtherCharges = React.forwardRef<HTMLDivElement, OtherChargesProps>((props, ref) => {
  const { formikProps, handleTotalOtherCharge, OCList, handleOCListChange } = props;
  const { values } = formikProps;

  return (
    <div ref={ref} className="units-booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <h5>OTHER CHARGES</h5>

        <div>
          <table className="table">
            <thead>
              <th>Sr No</th>
              <th>Title</th>
              <th>Distribution Method</th>
              <th>Area</th>
              <th>Rate</th>
              <th className="text-right">Amount</th>
            </thead>
            {values.calculation_method ? (
              <tbody>
                {OCList?.map((x, i) => (
                  <OtherChargeRow
                    key={i}
                    formikProps={formikProps}
                    handleOCListChange={handleOCListChange}
                    i={i}
                    x={x}
                  />
                ))}
                <tr>
                  <td className="text-right" colSpan={6}>
                    <div className="total-background">
                      <label style={{ fontSize: '1.1rem' }}>
                        <span style={{ marginRight: 6 }}>Total</span>
                        <span style={{ marginRight: 6 }}>:</span>
                        <span> ₹ </span>
                        <span>{handleTotalOtherCharge()}</span>
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : undefined}
          </table>
        </div>
      </div>
    </div>
  );
});

export default OtherCharges;
