import { FormikProps } from 'formik';
import { useSyncedFields } from 'hooks/useDiscountCalculator';
import React from 'react';
const styles = {
  labelFont: {
    fontSize: 16,
    fontWeight: 500,
    color: '#53575b',
  },
};
interface GovtTaxesProps {
  formikProps: FormikProps<any>; 
  handleTotalOtherCharge: () => string;
}

const GovtTaxes = React.forwardRef<HTMLDivElement, GovtTaxesProps>((props, ref) => {
  const { formikProps, handleTotalOtherCharge } = props;
  const { values, setFieldValue } = formikProps;

  // discount calculations hook
  const gstSyncedFields = useSyncedFields(
    Number(values.basic_rate_basic_amount) + parseFloat(handleTotalOtherCharge()),
    'gst_amt',
    'gst_per',
    setFieldValue,
  );

  const stampDutySyncedFields = useSyncedFields(
    Number(values.basic_rate_basic_amount) + parseFloat(handleTotalOtherCharge()),
    'stampduty_amount',
    'stampduty_per',
    setFieldValue,
  );

  const registrationSyncedFields = useSyncedFields(
    Number(values.basic_rate_basic_amount) + parseFloat(handleTotalOtherCharge()),
    'reg_amount',
    'reg_per',
    setFieldValue,
  );

  return (
    <div className="units-booking-form-box shwan-form mt-4" ref={ref}>
      <div className="booking-form-col-6" style={{ borderRight: 'none' }}>
        <h5>Goverment taxes</h5>
        <div className="form-row">
          <div className="col-md-12">
            <label
              className="total-background"
              style={{
                display: 'flex',
                gap: '3rem',
              }}
            >
              <span style={{ fontSize: 15, fontWeight: 400, color: '#3d3f43' }}>
                Sub Total Amount{' '}
              </span>
              <span>
                :
                <span style={{ fontSize: '1.1rem', marginLeft: '.5rem' }}>
                  {values.calculation_method
                    ? (
                        Number(values.basic_rate_basic_amount) +
                        parseFloat(handleTotalOtherCharge())
                      ).toFixed(2)
                    : '0.00'}
                </span>
              </span>
            </label>
          </div>
        </div>
        <div className="form-row ml-2">
          <div className="col-2 form-col-gap">
            <label style={styles.labelFont}>GST</label>
          </div>
          <div className="col-2">
            <input
              className="form-control"
              name="gst_per"
              type="number"
              value={values.gst_per}
              onChange={gstSyncedFields.onChangePercent}
              // onInput={handleNumberInput}
            />
          </div>
          <div className="col-7">
            <input
              className="form-control"
              name="gst_amt"
              style={{ textAlign: 'right' }}
              type="number"
              value={values.gst_amt}
              onChange={gstSyncedFields.onChangeAmount}
              // onInput={handleNumberInput}
            />
          </div>
        </div>
        <div className="form-row ml-2">
          <div className="col-2 form-col-gap">
            <label style={styles.labelFont}>Stamp Duty</label>
          </div>
          <div className="col-2">
            <input
              className="form-control"
              name="stampduty_per"
              type="number"
              value={values.stampduty_per}
              onChange={stampDutySyncedFields.onChangePercent}
              // onInput={handleNumberInput}
            />
          </div>
          <div className="col-7">
            <input
              className="form-control"
              name="stampduty_amount"
              type="number"
              value={values.stampduty_amount}
              onChange={stampDutySyncedFields.onChangeAmount}
              style={{ textAlign: 'right' }}
              // onInput={handleNumberInput}
            />
          </div>
        </div>
        <div className="form-row ml-2">
          <div className="col-2 form-col-gap">
            <label style={styles.labelFont}>Registration</label>
          </div>
          <div className="col-2">
            <input
              className="form-control"
              name="reg_per"
              type="number"
              value={values.reg_per}
              onChange={registrationSyncedFields.onChangePercent}
              // onInput={handleNumberInput}
            />
          </div>
          <div className="col-7">
            <input
              className="form-control"
              name="reg_amount"
              type="number"
              value={values.reg_amount}
              onChange={registrationSyncedFields.onChangeAmount}
              style={{ textAlign: 'right' }}
              // onInput={handleNumberInput}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12 ">
            <label
              className="total-background"
              style={{
                display: 'flex',
                gap: '6rem',
              }}
            >
              <span style={{ fontSize: 15, fontWeight: 400, color: '#3d3f43' }}>Taxes total</span>
              <span>
                :<span style={{ fontSize: '1.1rem' }}> ₹ </span>
                <span style={{ fontSize: '1.1rem', marginLeft: '.5rem' }}>
                  {(
                    Number(values.gst_amt) +
                    Number(values.stampduty_amount) +
                    Number(values.reg_amount)
                  ).toFixed(2)}
                </span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
});

export default GovtTaxes;
