/* eslint-disable @typescript-eslint/no-empty-function */
import './UE_BookingForm.css';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppBar, Box, Chip, IconButton, styled, Typography } from '@mui/material';
import Loader from 'components/atoms/Loader';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getAreaInfo,
  getCLPInstallmentOptions,
  getOtherChargesList,
  getOtherExtraCharges,
  getUnitInfo,
  getUnitParkingInfo,
} from 'redux/sales';
import { useAppDispatch, useAppSelector } from 'redux/store';

// import BookingHeader from './components/BookingHeader';
import QutotationInformation from './components/QutotationInformation';
import UnitInfo from './components/UnitInfo';
const PageHeader = styled(AppBar)`
  background: #f6f7fb;
  box-shadow: none;
  color: #041d36;
  align-items: center;
  padding: 10px 20px 10px 40px;
  justify-content: space-between;
  border-bottom: 0.5px solid #d7d3d373;
`;

const UE_BookingForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const edit = false;

  // url params
  // const project_id = searchParams.get('project_id');
  // const project_id = '260';
  // const unit_id = searchParams.get('unit_id');
  // const tower_id = searchParams.get('tower_id');
  // const autoIncremented_Tower = searchParams.get('tower_id');

  const project_id = Cookies.get('_hjproject_idUser_2492391');
  const unit_id = Cookies.get('_hjunit_idUser_2492391');
  const tower_id = Cookies.get('_hjtower_idUser_2492391');
  // const autoIncremented_Tower = Cookies.get('_hjtower_idUser_2492391');
  // const unit_id = '145';
  // const tower_id = '2';
  // redux state values
  const {
    unitParkingInfo,
    unitAreaInfo,
    otherChargesList,
    extraChargesList,
    loading,
    CLPInstallmentList: installmentsList,
  } = useAppSelector(s => s.sales);

  const [extraCharges, setExtraCharges] = useState([]);
  const [baseAmount, setBaseAmount] = useState<number>();
  const [OCList, setOCList] = useState([]);

  // parkingInfo
  const unitParkingInfoValues = useMemo(() => {
    return unitParkingInfo?.all_parking_units?.filter(e => e.allotment_data === unit_id);
  }, [unitParkingInfo?.all_parking_units, unit_id]);

  // form initials
  const initialValues = useMemo(() => {
    return {
      project_id,
      unit_id: unit_id,
      parking_no: unitParkingInfoValues?.map(e => e.id).toString(),
      calculation_method: '',
      basic_rate_no: 1,
      basic_rate_description: 'Basic rate of unit',
      basic_rate_area: unitAreaInfo?.super_build_up_area || 0,
      basic_rate: unitAreaInfo?.rate_base_amt || 0,
      basic_rate_disc_amt: 0,
      basic_rate_disc_per: 0,
      basic_rate_basic_amount: 0,
      other_charges: [],
      other_charges_total: 0,
      other_charges_total_discount: 0,
      custom_payment_remark_id: 0,
      custom_payment_remark: '',
      disc_remarks: '',
      extra_charges: [],
      gst_per: 0,
      gst_amt: 0,
      stampduty_per: 0,
      stampduty_amount: 0,
      reg_per: 0,
      reg_amount: 0,
      total_gove_tax: 0,
      is_loan: 'yes',
      loan_amt: 0,
      bank: 0,
      bank_id: 0,
      loan_remarks: '',
      custom_payment_total_amount: 0,
      extra_charges_total: 0,
      ownership: [],
      installment_type: '',
      installments: [],
      clp_installments: [],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    project_id,
    unitAreaInfo?.rate_base_amt,
    unitAreaInfo?.super_build_up_area,
    unitParkingInfoValues,
    unit_id,
  ]);

  // Api calls
  useEffect(() => {
    dispatch(getUnitInfo({ project_id: project_id, tower_id: tower_id }));
    dispatch(getUnitParkingInfo({ project_id: project_id }));
    dispatch(getAreaInfo({ project_id, project_main_types: 6, unit_id }));
    dispatch(getOtherChargesList({ project_id, unit_id }));
    dispatch(getOtherExtraCharges({ project_id, unit_id }));
    dispatch(
      getCLPInstallmentOptions({ project_id: Number(project_id), tower_id: Number(tower_id) }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Charges list populate
  useEffect(() => {
    // OC
    handleMapOtherCharges();
    handle_Other_Charge_Row_Total();
    // EC
    handleUpdateExtraCharges();
    handle_Extra_Charge_Row_Total();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherChargesList, unitAreaInfo, extraChargesList]);

  // other charges update, delete
  function handleMapOtherCharges() {
    const OC = otherChargesList.other_charge_unit_rates;
    const updatedData = OC?.map(x => {
      return {
        amount_type: x?.amount_type,
        fixed_amounts: Number(x.fixed_amounts) || 0,
        ratebase_amounts: Number(x.ratebase_amounts) || 0,
        other_charges_no: x.id,
        other_charges_title: x.title,
        other_charges_distribution_method: '',
        other_charges_area: x.area || 0,
        other_charges_rate:
          x.amount_type === 'ratebase_amt' ? Number(x.ratebase_amounts) : Number(x.fixed_amounts),
        other_charges_disc_amt: 0,
        other_charges_disc_per: 0,
        other_charges_amount: 0,
        other_charges_base: 0,
      };
    });
    setOCList(updatedData);
  }

  const handleTotalOtherCharge = useCallback(() => {
    let total = 0;
    OCList?.forEach(charge => {
      total += parseFloat(charge?.other_charges_amount) || 0;
    });
    return Number(total).toFixed(2);
  }, [OCList]);

  function handle_Other_Charge_Row_Total() {
    OCList?.map((x, index) => {
      const calculatedAmount =
        x.amount_type === 'ratebase_amt'
          ? Number(x.other_charges_area) * x.other_charges_rate
          : x.fixed_amounts;

      setOCList(prevList => {
        const newUnitRates = [...prevList];
        newUnitRates[index] = {
          ...newUnitRates[index],
          other_charges_amount:
            calculatedAmount === 0
              ? 0
              : calculatedAmount.toFixed(2) - newUnitRates[index].other_charges_disc_amt,
        };
        return newUnitRates;
      });
    });
  }

  // extra charges update, delete & add
  function handleUpdateExtraCharges() {
    const EC = extraChargesList?.other_charge_unit_rates;

    const updatedData = EC?.map(x => ({
      amount_type: x?.amount_type,
      fixed_amounts: Number(x.fixed_amounts) || 0,
      ratebase_amounts: Number(x.ratebase_amounts) || 0,
      title: x.title,
      extra_charges_no: x.id,
      extra_charges_title: x.title,
      extra_charges_distribution_method: '',
      extra_charges_area: x.area || 0,
      extra_charges_rate:
        x.amount_type === 'ratebase_amt' ? Number(x.ratebase_amounts) : Number(x.fixed_amounts),
      extra_charges_disc_amt: 0,
      extra_charges_disc_per: 0,
      extra_charges_amt: 0,
      extra_charges_total: 0,
      extra_charges_base: 0,
    }));

    setExtraCharges(updatedData);

    handle_Extra_Charge_Row_Total();
  }

  function handle_Extra_Charge_Row_Total() {
    setExtraCharges(prevList =>
      prevList?.map(x => {
        const Amt =
          x.amount_type === 'ratebase_amt'
            ? Number(x.extra_charges_area) * x.extra_charges_rate
            : x.extra_charges_rate;
        return {
          ...x,
          extra_charges_total: Amt === 0 ? 0 : Amt - x.extra_charges_disc_amt,
        };
      }),
    );
  }

  const handleTotalExtraCharge = () => {
    let total = 0;
    extraCharges?.forEach(charge => {
      total += charge.extra_charges_total || 0;
    });

    return Number(total).toFixed(2);
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const { values, setFieldValue } = formik;

  // govt Taxes
  useEffect(() => {
    const { basic_rate_area = 0, basic_rate = 0, calculation_method } = values;

    const basic_rate_total = basic_rate_area * basic_rate;
    setBaseAmount(calculation_method === 'rate_base' ? basic_rate_total : basic_rate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.basic_rate_area, values.basic_rate, values.calculation_method]);

  useEffect(() => {
    const { basic_rate_disc_amt = 0 } = values;

    // if (values.calculation_method === 'rate_base' || values.calculation_method === 'fixed_amount') {
    setFieldValue(
      'basic_rate_basic_amount',
      parseFloat((baseAmount - basic_rate_disc_amt).toFixed(2)),
    );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseAmount, values.basic_rate_disc_amt, values.basic_rate_disc_per]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      basic_rate:
        values.calculation_method === 'rate_base'
          ? unitAreaInfo?.rate_base_amt
          : unitAreaInfo?.fixed_amount || 0,
      basic_rate_disc_amt: 0,
      basic_rate_disc_per: 0,
      basic_rate_basic_amount: 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.calculation_method]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loader loading={loading} />

      {/* Header */}
      {/* <BookingHeader
        formikProps={formik}
        handleDownloadPDF={function (): void {
          throw new Error('Function not implemented.');
        }}
        navigate={navigate}
      /> */}
      <PageHeader position="sticky">
        <Box className="d-flex align-item-center justify-content-between w-100">
          <Box className="d-flex align-item-center">
            <IconButton
              style={{ backgroundColor: '#e5eafa', color: '#4872f4' }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <Box className="d-flex align-item-center px-2">
              <Typography variant="h5">
                Booking Quotation
                {/* <div style={chipStyle}>
              <span>Beta</span>
            </div> */}
              </Typography>
              <Chip color="success" label="Beta" style={{ marginLeft: 10 }} />
            </Box>
          </Box>
        </Box>

        {/* error box */}
        {/* <Box className="w-100 mt-2 mx-4">
        {validationErrors.length ? (
          <Box
            sx={{
              background: 'rgba(255, 93, 93, 0.1)',
              borderRadius: 2,
              padding: `5px`,
              fontSize: '1rem',
            }}
          >
            <ul>
              {validationErrors?.map((err, index) => {
                return (
                  <li key={index}>
                    <Typography color={'#ff5d5d'} variant="h6">
                      {JSON.parse(JSON.stringify(err))}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </Box>
        ) : undefined}
      </Box> */}
      </PageHeader>
      <section className="booking-form-sec pt-0 bookingFormUpdated">
        <UnitInfo formikProps={formik} unit_id={unit_id} />
        <div className="booking-form-row">
          {installmentsList.map((installment, index) => (
            <QutotationInformation
              key={index}
              handleTotalExtraCharge={handleTotalExtraCharge}
              handleTotalOtherCharge={handleTotalOtherCharge}
              installmentId={installment.id}
              rate={installment.rate}
              title={installment.name}
            />
          ))}
        </div>
      </section>
    </>
  );
};

export default UE_BookingForm;
