import { cloneDeep } from 'lodash';
import { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { getInstallmentDetails, getInstallmentOptions } from 'redux/sales';
import { useAppDispatch, useAppSelector } from 'redux/store';

import CustomInstallmentRow from './CustomInstallmentRow';

const CustomInstallments = props => {
  const dispatch = useAppDispatch();

  const { formikProps, project_id, extraCharges, OCList } = props;
  const { values, setFieldValue } = formikProps;

  // redux state values
  const { installmentsList, installmentsInformation } = useAppSelector(s => s.sales);

  useEffect(() => {
    dispatch(getInstallmentOptions({ project_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleTotalPaymentCharge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.installments]);

  useEffect(() => {
    mapPaymentInstallments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installmentsInformation]);

  const mapPaymentInstallments = () => {
    const schedules = installmentsInformation?.payment_scheduled_details_master?.length
      ? installmentsInformation?.payment_scheduled_details_master || []
      : values.installments;

    const updatedData = schedules?.map(installment => ({
      custom_payment_installment: installmentsInformation?.payment_scheduled_details_master?.length
        ? installment.title
        : installment?.custom_payment_installment,
      installment_due_date: installmentsInformation?.payment_scheduled_details_master?.length
        ? ''
        : installment?.installment_due_date,
      installment_per: installmentsInformation?.payment_scheduled_details_master?.length
        ? Number(installment?.percentage)
        : Number(installment?.installment_per),
      installment_basic_amt: installmentsInformation?.payment_scheduled_details_master?.length
        ? 0
        : Number(installment?.installment_basic_amt),
      installment_otherchages_amt: installmentsInformation?.payment_scheduled_details_master?.length
        ? 0
        : Number(installment?.installment_otherchages_amt),
      installment_extrachages_amt: installmentsInformation?.payment_scheduled_details_master?.length
        ? 0
        : Number(installment?.installment_extrachages_amt),
      installment_amount: installmentsInformation?.payment_scheduled_details_master?.length
        ? 0
        : Number(installment?.installment_amount),
      gst: installmentsInformation?.payment_scheduled_details_master?.length
        ? 0
        : Number(installment?.gst),
    }));

    if (
      !updatedData.filter(
        installment => installment.custom_payment_installment === 'Other Charges (Separately)',
      )?.length
    ) {
      updatedData?.push({
        installment_otherchages_amt: 0,
        installment_extrachages_amt: 0,
        installment_amount: 0,
        custom_payment_no: (values?.installments?.length ?? 0) + 1,
        gst: 0,
        custom_payment_installment: 'Other Charges (Separately)',
        installment_due_date: '',
        lastRow: 'true',
      } as any);
    }

    setFieldValue('installments', updatedData);
  };

  const handleTotalPaymentCharge = () => {
    let total = 0;
    values.installments?.forEach(charge => {
      total += parseFloat(charge?.installment_amount) || 0;
    });

    setFieldValue('custom_payment_total_amount', total.toFixed(2));
    return total.toFixed(2);
  };

  const handleDeleteInstallment = (index: number) => {
    const updatedInstallments = cloneDeep(values.installments);

    updatedInstallments.splice(index, 1);

    setFieldValue('installments', updatedInstallments);
  };

  const handlePaymentScheduleUpdate = (index, field, value) => {
    const newUnitRates = cloneDeep(values.installments);

    newUnitRates[index] = {
      ...newUnitRates[index],
      [field]: value,
    };

    const basicAmount = Number(newUnitRates[index].installment_basic_amt) || 0;
    const otherChargesAmt = Number(newUnitRates[index].installment_otherchages_amt) || 0;
    const extraChargesAmt = Number(newUnitRates[index].installment_extrachages_amt) || 0;
    // const percentage = Number(newUnitRates[index].installment_per).toFixed(2) || 0;

    // Calculate the totalPaymentSchedule
    const totalPaymentSchedule =
      basicAmount + otherChargesAmt + newUnitRates[index].gst + extraChargesAmt;

    newUnitRates[index].installment_basic_amt = Number(basicAmount.toFixed(2));

    newUnitRates[index].installment_amount = Number(totalPaymentSchedule.toFixed(2));

    setFieldValue('installments', newUnitRates);
  };

  const addPaymentSchedule = () => {
    const updatedInstallments = cloneDeep(values.installments);
    const newInstallment = {
      custom_payment_no: values.installments?.length + 1,
      custom_payment_installment: '',
      installment_due_date: '',
      installment_per: 0,
      installment_basic_amt: 0,
      installment_otherchages_amt: 0,
      installment_extrachages_amt: 0,
      installment_amount: 0,
      gst: 0,
      custom: true,
    };
    updatedInstallments.push(newInstallment);

    // keeping other Charges Separately in last
    updatedInstallments.push(
      updatedInstallments.splice(
        updatedInstallments.findIndex(v => v.lastRow),
        1,
      )[0],
    );

    setFieldValue('installments', updatedInstallments);
  };

  const handleInstallmentChange = e => {
    dispatch(
      getInstallmentDetails({
        project_id: Number(project_id),
        payment_scheduled_master_id: e.value,
      }),
    );
  };

  // installment calculations
  const calculateInstallments = () => {
    let updatedList = cloneDeep(values.installments);

    updatedList?.map(installment => {
      installment.installment_basic_amt = 0;
      installment.installment_otherchages_amt = 0;
      installment.installment_extrachages_amt = 0;
      installment.installment_amount = 0;
      installment.gst = 0;

      return installment;
    });

    updatedList?.map(installment => {
      if (!installment.lastRow) {
        const calculatedAmount =
          (Number(values.basic_rate_basic_amount) * installment.installment_per) / 100;

        installment.installment_basic_amt = Number(calculatedAmount);
        installment.installment_amount += Number(calculatedAmount);

        return installment;
      }
      return installment;
    });

    extraCharges?.map(extraCharge => {
      const { extra_charges_distribution_method, extra_charges_total } = extraCharge;
      const installmentLen = updatedList?.length > 1 ? updatedList?.length - 1 : 1;

      switch (extra_charges_distribution_method) {
        case 'Equally with all installments': {
          const equallyDistributedAmount = extra_charges_total / installmentLen;

          updatedList = updatedList?.map((installment, index) => {
            if (index !== installmentLen && !installment?.lastRow) {
              installment.installment_extrachages_amt += Number(equallyDistributedAmount);

              installment.installment_amount += Number(equallyDistributedAmount);

              return installment;
            }
            return installment;
          });

          break;
        }

        case 'Proportionately with all installment': {
          updatedList = updatedList.map((installment, index) => {
            if (index !== installmentLen && !installment?.lastRow) {
              installment.installment_extrachages_amt += Number(
                (extra_charges_total * installment.installment_per) / 100,
              );

              installment.installment_amount += Number(
                (extra_charges_total * installment.installment_per) / 100,
              );
            }
            return installment;
          });
          break;
        }

        case 'Proportionately with all installment(Except First)': {
          const proportionatelyDistributedAmount = extra_charges_total / (installmentLen - 1);

          updatedList = updatedList.map((installment, index) => {
            if (index !== 0 && index !== installmentLen && !installment?.lastRow) {
              installment.installment_extrachages_amt += Number(
                (proportionatelyDistributedAmount * installment.installment_per) / 100,
              );

              installment.installment_amount += Number(
                (proportionatelyDistributedAmount * installment.installment_per) / 100,
              );
            }
            return installment;
          });
          break;
        }

        case 'Connect with last installment': {
          updatedList = updatedList.map((installment, index) => {
            if (index === installmentLen - 1 && !installment?.lastRow) {
              installment.installment_extrachages_amt += Number(extra_charges_total);

              installment.installment_amount += Number(extra_charges_total);
            }
            return installment;
          });
          break;
        }

        case 'Dont connect with installment': {
          updatedList = updatedList.map(installment => {
            if (
              installment?.lastRow ||
              installment?.custom_payment_installment === 'Other Charges (Separately)'
            ) {
              installment.installment_extrachages_amt += Number(extra_charges_total);

              installment.installment_amount += Number(extra_charges_total);
            }
            return installment;
          });
          break;
        }

        default:
      }
    });

    OCList?.map(oclist => {
      const { other_charges_distribution_method, other_charges_amount } = oclist;
      const installmentLen = updatedList?.length > 1 ? updatedList?.length - 1 : 1;

      switch (other_charges_distribution_method) {
        case 'Equally with all installments': {
          const equallyDistributedAmount = other_charges_amount / installmentLen;

          updatedList = updatedList.map((installment, index) => {
            if (index !== installmentLen && !installment?.lastRow) {
              installment.installment_otherchages_amt += Number(equallyDistributedAmount);

              installment.installment_amount += Number(equallyDistributedAmount);
              return installment;
            }
            return installment;
          });

          break;
        }

        case 'Proportionately with all installment': {
          updatedList = updatedList.map((installment, index) => {
            if (index !== installmentLen && !installment?.lastRow) {
              installment.installment_otherchages_amt += Number(
                (other_charges_amount * installment.installment_per) / 100,
              );
              installment.installment_amount += Number(
                (other_charges_amount * installment.installment_per) / 100,
              );
            }
            return installment;
          });

          break;
        }

        case 'Proportionately with all installment(Except First)': {
          const proportionatelyDistributedAmount = other_charges_amount / (installmentLen - 1);

          updatedList = updatedList.map((installment, index) => {
            if (index !== 0 && index !== installmentLen && !installment?.lastRow) {
              installment.installment_otherchages_amt += Number(
                (proportionatelyDistributedAmount * installment.installment_per) / 100,
              );
              installment.installment_amount += Number(
                (proportionatelyDistributedAmount * installment.installment_per) / 100,
              );
            }
            return installment;
          });

          break;
        }

        case 'Connect with last installment': {
          updatedList = updatedList.map((installment, index) => {
            if (index === installmentLen - 1 && !installment?.lastRow) {
              installment.installment_otherchages_amt += Number(
                Number(other_charges_amount).toFixed(2),
              );
              installment.installment_amount += Number(Number(other_charges_amount).toFixed(2));
            }
            return installment;
          });

          break;
        }

        case 'Dont connect with installment': {
          updatedList = updatedList.map(installment => {
            if (
              installment?.lastRow ||
              installment?.custom_payment_installment === 'Other Charges (Separately)'
            ) {
              installment.installment_otherchages_amt += Number(
                Number(other_charges_amount).toFixed(2),
              );
              installment.installment_amount += Number(Number(other_charges_amount).toFixed(2));
            }
            return installment;
          });

          break;
        }

        default:
      }
    });

    updatedList?.map(installment => {
      const calculatedGSTAmount =
        ((Number(installment.installment_basic_amt) +
          Number(installment.installment_otherchages_amt)) *
          values.gst_per) /
        100;

      installment.gst = Number(calculatedGSTAmount.toFixed(2));
      installment.installment_amount += Number(calculatedGSTAmount.toFixed(2));

      return installment;
    });

    setFieldValue('installments', updatedList);
  };

  // installment Options
  const installmentOptions = useMemo(() => {
    return installmentsList?.payment_scheduled_master?.map(e => ({
      label: e.title,
      value: e.id,
    }));
  }, [installmentsList]);

  return (
    <div className="booking-form-box shwan-form pt-0 mt-4 shadow-none">
      <div className="booking-form-col-12">
        {/* <Typography variant="subtitle2">Custom Installments</Typography> */}

        <div className="form-row">
          <div className="col-6 ">
            <label>Select Payment Installment</label>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <Select
                closeMenuOnSelect={true}
                options={installmentOptions}
                placeholder="Select Payment Installment"
                styles={{
                  container: (base: any) => ({
                    ...base,
                    marginTop: 10,
                    marginBottom: 20,
                    width: '25rem',
                  }),
                }}
                onChange={handleInstallmentChange}
              />
              <button
                className="Btn btn-lightblue-primary lbps-btn py-2 mr-0"
                id="butng"
                type="button"
                onClick={() => {
                  calculateInstallments();
                }}
              >
                Calculate
              </button>
              {
                <button
                  className="Btn btn-lightblue-primary lbps-btn py-2 mr-0"
                  id="butng"
                  type="button"
                  onClick={() => {
                    addPaymentSchedule();
                  }}
                >
                  Add
                </button>
              }
            </div>
          </div>
        </div>
        <div>
          <table className="table">
            <thead>
              <th>Sr No</th>
              <th>Installment Name</th>
              <th>Due Date</th>
              <th>%</th>
              <th>Basic Amount</th>
              <th>Other Charges Amount</th>
              <th>GST</th>
              <th>Extra Charges Amount</th>
              <th className="text-right">Installment Amount</th>
              <th></th>
            </thead>
            <tbody>
              {values.installments?.map((e, i) => (
                <CustomInstallmentRow
                  key={i}
                  e={e}
                  formikProps={formikProps}
                  handleDeleteInstallment={handleDeleteInstallment}
                  handlePaymentScheduleUpdate={handlePaymentScheduleUpdate}
                  i={i}
                />
              ))}
              <tr>
                <td
                  className="text-right"
                  colSpan={10}
                  style={{ borderTop: 'none', padding: '5px 0px' }}
                >
                  <div className="total-background">
                    <label style={{ fontSize: '1.1rem' }}>
                      <span style={{ marginRight: 6 }}> Installments Total</span>
                      <span style={{ marginRight: 6 }}>:</span>
                      <span> ₹ {values.custom_payment_total_amount}</span>
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomInstallments;
